import React from "react";
import SliderComp from "./SliderComp"
import Featured from "./Featured";
import Gallery from "./Gallery";
import ContactForm from "./ContactForm";
import Container from '@mui/material/Container'




function Home() {
  return (
    <>
        <Container maxWidth="lg" sx={{p:"0px !important"}}>
          <SliderComp/>
          <Featured/>
          <Gallery />
          <ContactForm />   
        </Container>

    </>
  )
}

export default Home