import React from "react";
import { AppBar,Toolbar,Grid, Typography, IconButton, Container } from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ItunesIcon from './images/itunes.svg'
import SpotifyIcon from './images/spotify.svg'
import Divider from '@mui/material/Divider';
import { useMediaQuery, useTheme, } from "@mui/material";
import {useTranslation} from "react-i18next";


function Footer() {

    const {t, i18n}= useTranslation();

    const middleTitleTextStyle = {
        textAlign: "center",
        color: 'white',
        fontWeight: 700,      
    }
    const leftTitleTextStyle = {
        textAlign: "left",
        color: 'white',
        fontWeight: 700,        
    }
    const mobileTitleStyle = {
        textAlign: "center",
        color: 'white',
        fontWeight: 700,        
    }
    const mobileTextStyle = {
        textAlign: "center",
        color: '#EEEEEE',
        fontWeight: 400,     
    }
    const rightTitleTextStyle = {
        textAlign: "right",
        color: 'white',
        fontWeight: 700,       
    }
    const leftSubTextStyle = {
        textAlign: "left",
        color: '#EEEEEE',
        fontWeight: 400,

    }
    const MailTextStyle = {
        textAlign: "center",
        color: '#EAEAEA',
        fontWeight: 400,
    }
    const iconStyle = {
        fontSize: 30,
        color: 'white'
    }
    const appBarStyle = {
        top: 'auto',
        bottom: 0,
        height: 'auto',
        backgroundColor:'#404041',
        position: 'static'

    }

    const theme = useTheme();
    const isDownMD = useMediaQuery(theme.breakpoints.down("md"));

    return (

        <>
            <AppBar style={appBarStyle}>
                <Toolbar>
                    <Container maxWidth="lg" sx={{p:"0px !important"}}>
                    {isDownMD ? ( 
                        <Grid container sx={{mt:2, mb:7}}  direction="column" justifyContent="center" alignItems="center" spacing={1}>
                            <Grid item >
                                <Typography variant="h6" style={mobileTitleStyle}>{t("footer.turkiye")}</Typography>
                                <Typography variant="body2"  style={mobileTextStyle}>Yalıboyu Cad. Arabacılar Sok. No: 28 <br/> 34684 Beylerbeyi Üsküdar - İstanbul</Typography>                          
                            </Grid>

                            <Divider orientation="vertical" flexItem sx={{color:'#FFFFFF', border: 1, opacity: 0.5,mt:1}}/>

                            <Grid item >
                                <Typography variant="h6"  style={mobileTitleStyle}>{t("footer.germany")}</Typography>
                                <Typography variant="body2"  style={mobileTextStyle}>Ferdinandstrasse 29 <br/> 22043 Hamburg</Typography>
                        
                            </Grid>

                            <Divider orientation="vertical" flexItem sx={{color:'#FFFFFF', border: 1, opacity: 0.5,mt:1}}/>

                            <Grid item >
                                <Typography variant="body1"  style={mobileTextStyle}>info@musicarium.live</Typography>                       
                            </Grid>
                            
                            <Divider orientation="vertical" flexItem sx={{color:'#FFFFFF', border: 1, opacity: 0.5,mt:1}}/>

                            <Grid item >
                                <Typography variant="h6" color="initial" style={middleTitleTextStyle}>{t("footer.followUs")}</Typography>
                                <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={1} sx={{mt:0}}>
                                    <Grid item>
                                        <IconButton href="https://instagram.com/musicarium.live?igshid=YmMyMTA2M2Y" target="_blank">
                                            <InstagramIcon style={iconStyle}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton href="https://www.facebook.com/musicariumentertainment" target="_blank">
                                            <FacebookIcon style={iconStyle}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton href="https://www.linkedin.com/company/musicariumentertainment/" target="_blank"> 
                                            <LinkedInIcon style={iconStyle}/>
                                        </IconButton>
                                    </Grid>
                                    {/* <Grid item>
                                        <IconButton>
                                            <img src={ItunesIcon} alt='Itunes' style={{width:30}}/>
                                        </IconButton>
                                    </Grid>                                
                                    <Grid item>
                                        <IconButton>
                                            <img src={SpotifyIcon} alt='Spotify' style={{width:30}}/>
                                        </IconButton>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    ):(
                        <Grid container sx={{mt:5, mb:7}}  direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item >
                                <Typography variant="h6" style={leftTitleTextStyle}>{t("footer.turkiye")}</Typography>
                                <Typography variant="body2"  style={leftSubTextStyle}>Yalıboyu Cad. Arabacılar Sok. No: 28 <br/> 34684 Beylerbeyi Üsküdar - İstanbul</Typography>                          
                            </Grid>

                            <Divider orientation="vertical" flexItem sx={{color:'#FFFFFF', border: 1, opacity: 0.5}}/>

                            <Grid item >
                                <Typography variant="h6"  style={leftTitleTextStyle}>{t("footer.germany")}</Typography>
                                <Typography variant="body2"  style={leftSubTextStyle}>Ferdinandstrasse 29 <br/> 22043 Hamburg</Typography>
                        
                            </Grid>

                            <Divider orientation="vertical" flexItem sx={{color:'#FFFFFF', border: 1, opacity: 0.5}}/>

                            <Grid item >
                                <Typography variant="body1"  style={MailTextStyle}>info@musicarium.live</Typography>                       
                            </Grid>
                            
                            <Divider orientation="vertical" flexItem sx={{color:'#FFFFFF', border: 1, opacity: 0.5}}/>

                            <Grid item >
                                <Typography variant="h6" color="initial" style={middleTitleTextStyle}>{t("footer.followUs")}</Typography>
                                <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={1} sx={{mt:0}}>
                                    <Grid item>
                                        <IconButton href="https://instagram.com/musicarium.live?igshid=YmMyMTA2M2Y" target="_blank">
                                            <InstagramIcon style={iconStyle}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton href="https://www.facebook.com/musicariumentertainment" target="_blank">
                                            <FacebookIcon style={iconStyle}/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton href="https://www.linkedin.com/company/musicariumentertainment/" target="_blank"> 
                                            <LinkedInIcon style={iconStyle}/>
                                        </IconButton>
                                    </Grid>
                                    {/* <Grid item>
                                        <IconButton>
                                            <img src={ItunesIcon} alt='Itunes' style={{width:30}}/>
                                        </IconButton>
                                    </Grid>                                
                                    <Grid item>
                                        <IconButton>
                                            <img src={SpotifyIcon} alt='Spotify' style={{width:30}}/>
                                        </IconButton>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                        
                    </Container>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default Footer;