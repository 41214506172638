import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import  './UsHover.css'


const textStyle = {
  color: '#4C2876',
  fontFamily:'Poppins',
  fontSize: 25,
  fontWeight: 800,
}


export default function UsCard({ img, text, cv, email }) {
  return (
    <div>
      <Grid container direction="column" alignItems="center" spacing={2} sx={{pb: 3}}>
        <Grid item>
          <div className="container">
            <img className="image" src={img} height="450px" width="370px" />
            <div className="overlay">
              <div className="text">{cv} <br/> {email}</div>

            </div>
          </div>
        </Grid>
        <Grid item>
          <Typography style={textStyle}>{text}</Typography>      
        </Grid>
      </Grid>        
    </div>
  )
}
