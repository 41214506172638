import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import YouTubeIcon from '@mui/icons-material/YouTube';
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#782166',
    '&:hover': {
      backgroundColor: '#782166'
    },
    width: 150,
    borderRadius: 12,
    height: 35,
    fontSize:13,
    fontFamily:'Poppins',
    fontWeight: 700,
  }));

const cardStyle = {
    height: '100%', 
    backgroundColor: '#522875',
    marginTop: 30,
    marginBottom: 20,
    width: '100%'
}
const textStyle = {
    color:'white',
    fontFamily:'Poppins',
    paddingBottom: 10,
}


export default function TelevisionCardLeft() {
  return (
    <>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={cardStyle}>
            <Grid item sx={{height: '100%', width:'60%', m:1,}}>
                <Grid container >
                    <Grid item sx={{height: '100%'}}>
                        <Typography variant="h4" style={textStyle} sx={{pb:"20px !important", pt:"10px"}}>ARAMIZDA MÜZİK VAR</Typography>
                        <Typography variant="body2" style={textStyle} sx={{pb:"30px !important"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur at nulla ut ante fermentum posuere. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque luctus tortor vel nulla rutrum porttitor. Vivamus at maximus sem. Integer imperdiet congue erat in viverra. Nullam lobortis arcu nisl, et sollicitudin velit suscipit quis. Curabitur ultrices purus ac erat consequat luctus. Mauris eleifend tincidunt rhoncus.</Typography>
                        <Typography variant="h6" style={textStyle} sx={{pb:"5px !important"}}>Lorem ipsum</Typography>
                        <Typography variant="body2" style={textStyle}>Lorem ipsum</Typography>
                    </Grid>
                    <Grid item width="100%" >
                        <Grid container justifyContent="space-between" direction="row" alignItems="center">
                            <Grid item> 
                                <ColorButton>
                                    DEVAMI
                                </ColorButton>
                            </Grid>
                            <Grid item>
                                <IconButton>
                                    <YouTubeIcon sx={{color: 'white', fontSize: 60,p:0,m:0}}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                      </Grid>             
                </Grid>
            </Grid>
            <Grid item sx={{height: '100%', width: '35%', m:1,}}>
                <Box sx={{height: 300, width: '100%', backgroundColor: '#414141'}}>

                </Box>
            </Grid>
        </Grid>
    </>
  )
}
