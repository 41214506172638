import React, { useState } from 'react';
import MenuDrawers from "./MenuDrawers";
import { AppBar, Toolbar, Tabs, Tab, IconButton, Button, Grid, MenuItem, Popover, Divider,Typography,Link } from "@mui/material";
import { useMediaQuery, useTheme, Container, } from "@mui/material";
import Mscrm_logo from './images/mscrm_Logo.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { NavLink } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//const pages = ['Products', 'Pricing', 'Blog'];

const HeaderLarge = () => {

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  

const {t, i18n}= useTranslation();

const clickHandle = lang => {
  i18n.changeLanguage(lang)
  handleClose2()
}
const changeLang = () => {
  if (localStorage.getItem("selectedLanguage") === 'en') {
    localStorage.setItem("selectedLanguage","tr");
  }
  else if (localStorage.getItem("selectedLanguage") === 'tr') {
    localStorage.setItem("selectedLanguage","en");
  }
}


  const [value, setValue] = useState();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const isDownMD = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event2) => {
    setAnchorEl2(event2.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };



  //Styles After That

  var logoStyle = {      
    width: 200,
    paddingRight: 0,
    paddingLeft: 0
  };
  var headerStyle = {
    background: 'linear-gradient(30deg, #492978 0%, #7A2165 100%)',
    maxWidth: "md",
    position: "absolute !important"

  };
  var tabStyle = {     
    fontFamily:'Poppins', 
    color:'white',
    fontSize: 18,
    fontWeight: 300,
    
  };
  var navButtonStyle = {     
    fontFamily:'Poppins', 
    color:'white',
    fontSize: 18,
    fontWeight: 300,   
  };
  var buttonNavStyle={
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  }
  var iconStyle = {
    color : 'black',
    fontSize: 20,
    padding:0,

  };
  var buttonStyle = {
    background: 'white',
    padding: 0,
    margin: 3,
    height: 30,
    width: 30,
  };
  var menuStyle = {
    fontFamily:'Poppins',
    fontSize: 17,
    fontWeight: 300, 
    backgroundColor: '#90278E',
    color: 'white',
    padding: 10,
    width: "150.5px",
    justifyContent: 'center'
  };
  var langMenuStyle = {
    fontFamily:'Poppins',
    backgroundColor: '#90278E',
    padding: 5,
    width: "100px",
    justifyContent: 'center'
  };
  var langButtonStyle = {     
    fontFamily:'Poppins', 
    color:'white',
    fontSize: 15,
    fontWeight: 300,   
  };
  var navlinkStyle = {
    textDecoration: "none"
  };

  return (
      <React.Fragment >
        <AppBar  style={headerStyle} position="sticky">
          {/* Editledim 22.02.2023 */}
          <Toolbar sx={{mx:0}}> 
          <Container maxWidth="lg" style={{paddingRight: "0px !important", paddingLeft: "0px !important"}}>   
            <Grid container  direction="row" justifyContent="space-between" alignItems="flex-end" sx={{mt:"30px !important",}}>
              <Grid item  sx={{mb: "10px !important"}}>
              {isDownMD ? ( 
                  
                <Button sx={{p:"0 !important", }}>
                  <NavLink to="/" style={navlinkStyle}>
                    <img src={Mscrm_logo} style= {{width: 100,paddingRight: 0,}} alt="" />
                  </NavLink>
                </Button>
                
              ):(
                <Button sx={{p:"0 !important", }}>
                  <NavLink to="/" style={navlinkStyle}>
                    <img src={Mscrm_logo} style={logoStyle} alt="" />
                  </NavLink>
                </Button>
              )}
              </Grid>
              {/* <Grid item > */}

                {/* <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={{ xl: 1, md: 1 }}> */}
                
                  <Grid item sx={{paddingRight: 5, paddingLeft: 5}}>
                      <NavLink to="/artists" style={navlinkStyle}>
                        <Button sx={{paddingLeft: "12px !important",paddingRight: "12px !important"}}>
                          {/* <Typography style={navButtonStyle}> {t("header.sanatcilar")} </Typography> */}
                          <Typography style={navButtonStyle}> {t('header.artist')} </Typography>

                        </Button>
                      </NavLink>
                      <NavLink to="/concerts" style={navlinkStyle}>
                        <Button sx={{paddingLeft: "12px !important",paddingRight: "12px !important"}}> 
                          <Typography style={navButtonStyle}> {t('header.concert')} </Typography>
                        </Button>
                      </NavLink>                       
                      <Button sx={{paddingLeft: "12px !important",paddingRight: "12px !important"}}
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                      >                        
                        <Typography style={navButtonStyle}> {t('header.production')} </Typography>                  
                      </Button>
                        <Popover 
                          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                          transformOrigin={{vertical: 'top', horizontal: 'center'}}
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                        >
                          <NavLink to="/music" style={navlinkStyle}>
                            <MenuItem style={menuStyle} onClick={handleClose}>                           
                              <Typography style={navButtonStyle}> {t('header.music')} </Typography>
                            </MenuItem>
                          </NavLink> 
                          <Divider sx={{m:"0px !important"}}/>
                          <NavLink to="/television" style={navlinkStyle}>
                            <MenuItem style={menuStyle} onClick={handleClose}>
                             <Typography style={navButtonStyle}> {t('header.television')} </Typography>
                            </MenuItem>
                          </NavLink>                                                 
                        </Popover>
                      <NavLink to="/us" style={navlinkStyle}>
                        <Button sx={{paddingLeft: "12px !important",paddingRight: "12px !important"}}>
                          <Typography style={navButtonStyle}> {t('header.aboutUs')} </Typography>                         
                        </Button>
                      </NavLink> 
                      <NavLink to="/contact" style={navlinkStyle}>                                               
                        <Button sx={{paddingLeft: "12px !important",paddingRight: "12px !important"}}>
                          <Typography style={navButtonStyle}> {t('header.contact')} </Typography>
                        </Button>
                      </NavLink>                              
                  </Grid>
                  
                  <Grid item >
                    <Grid container justifyContent="flex-end" sx={{pb:"6px !important"}}>
                      <Grid item>
                        <IconButton style={buttonStyle} href="https://www.instagram.com/musicarium.live/?igshid=YmMyMTA2M2Y" target="_blank">                        
                          <InstagramIcon style={iconStyle}/>
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton style={buttonStyle} href="https://www.facebook.com/musicariumentertainment" target="_blank">   
                          <FacebookIcon style={iconStyle}/>
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton style={buttonStyle} href="https://www.linkedin.com/company/musicariumentertainment/" target="_blank">   
                          <LinkedInIcon style={iconStyle}/>
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                   <Grid item>
                      <Button sx={{paddingLeft: "10px !important",paddingRight: "10px !important"}}
                            id="lang-button"
                            aria-controls={open2 ? 'lang-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open2 ? 'true' : undefined}
                            onClick={handleClick2}
                      >                        
                        <Typography style={langButtonStyle}> {i18n.language === 'en' ? 'TR' : 'EN'}</Typography>                  
                      </Button>
                        <Popover 
                          anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                          transformOrigin={{vertical: 'top', horizontal: 'center'}}
                          id="lang-menu"
                          anchorEl={anchorEl2}
                          open={open2}
                          onClose={handleClose2}
                        >
                            <MenuItem style={langMenuStyle} onClick={() => clickHandle('tr')}>                           
                              <Typography style={langButtonStyle}> TR </Typography>
                            </MenuItem>
                          <Divider sx={{m:"0px !important"}}/>
                            <MenuItem style={langMenuStyle} onClick={() => clickHandle('en')}>
                             <Typography style={langButtonStyle}> EN </Typography>
                            </MenuItem>
                        </Popover>

{/*
                    {/* <Tabs textColor="white">
                      <Tab sx={{fontFamily:'Poppins', fontWeight:200}} label="ENG"/>
                    </Tabs> 
                        <Button sx={{paddingLeft: "12px !important",paddingRight: "12px !important"}} onClick={changeLang}>
                          <Typography style={navButtonStyle}> ENG </Typography>                         
                        </Button>
                  </Grid>
                   */}
                {/* </Grid>
              */}
               </Grid>               
            </Grid>
          </Container>
          </Toolbar>
        </AppBar>
      </React.Fragment>
  );
}

export default HeaderLarge;