import React from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { Grid,Container,Typography,Button } from "@mui/material";
import { purple } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { useMediaQuery, useTheme, } from "@mui/material";
import concert1 from './images/Galeri/Ayhan Sicimoğlu Bursa.JPG'
import concert2 from './images/Galeri/Karsu Maximiles Black Turgutreis Arena.jpg'
import concert3 from './images/Galeri/Mor ve Ötesi Maximiles Black Turgutreis Arena.JPG'
import concert4 from './images/Galeri/Fatma Turgut Metropol.jpg'
import concert5 from './images/Galeri/Zengin Mutfağı Maximiles Black Turgutreis Arena.jpg'
import concert6 from './images/Galeri/P. Domingo, Karahan Aspendos.JPG'
import concert7 from './images/Galeri/MFÖ Harbiye.jpg'
import { NavLink } from "react-router-dom";
import {useTranslation} from "react-i18next";

var navlinkStyle = {
    textDecoration: "none",
    color: 'white'
  };
const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[700],
    '&:hover': {
      backgroundColor: purple[800],
    },
    width: 150,
    borderRadius: 12,
    height: 35,
    fontSize:13,
    fontFamily:'Poppins',
    fontWeight: 700,
  }));

  const containerStyle = {
    maxWidth: '100%',
    padding:10,
    paddingBottom: 60,
    paddingTop:60
}

const titleStyle = {
    color: "#46328F",
    fontFamily:'Poppins',
    fontWeight: 800,
    paddingBottom:30,
    fontSize:38,
}

function Gallery() {

    const theme = useTheme();
    const isDownMD = useMediaQuery(theme.breakpoints.down("md"));
    
    const {t, i18n}= useTranslation();

    return (

        <>
        <Container maxWidth="auto" style={containerStyle}>
            <Grid container direction="column" sx={{ml:5, mr:5, width: 'auto'}}>
                <Grid item>
                    <Typography variant="h5" style={titleStyle}>{t("gallery.gallery")}</Typography>
                </Grid>
                {isDownMD ? ( 
                <Grid item>
                    <ImageList sx={{ width: "auto"}} gap={0} cols={2}>
                        <ImageListItem cols={2} rows={2}>
                            <img src={concert1} alt='1'/>
                        </ImageListItem>
                        <ImageListItem>
                            <img src={concert2} alt='2'/>
                        </ImageListItem>                        
                        <ImageListItem>
                            <img src={concert3} alt='3'/>
                        </ImageListItem>
                        <ImageListItem>
                            <img src={concert4} alt='4'/>
                        </ImageListItem>                        
                        <ImageListItem>
                            <img src={concert5} alt='5'/>
                        </ImageListItem>
                        <ImageListItem>
                            <img src={concert6} alt='6'/>
                        </ImageListItem>
                        <ImageListItem>
                            <img src={concert7} alt='7'/>
                        </ImageListItem>
                    </ImageList>
                </Grid>
                ):(
                <Grid item>
                    <ImageList sx={{ width: "auto"}} gap={0} cols={5}>
                        <ImageListItem cols={2} rows={2}>
                            <img src={concert1} alt='1'/>
                        </ImageListItem>
                        <ImageListItem>
                            <img src={concert2} alt='2'/>
                        </ImageListItem>                        
                        <ImageListItem>
                            <img src={concert3} alt='3'/>
                        </ImageListItem>
                        <ImageListItem>
                            <img src={concert4} alt='4'/>
                        </ImageListItem>                        
                        <ImageListItem>
                            <img src={concert5} alt='5'/>
                        </ImageListItem>
                        <ImageListItem>
                            <img src={concert6} alt='6'/>
                        </ImageListItem>
                        <ImageListItem>
                            <img src={concert7} alt='7'/>
                        </ImageListItem>
                    </ImageList>
                </Grid>
                )}
                <Grid item sx={{pt: 5}}>
                    <Grid container  justifyContent="flex-end">
                        <NavLink style={navlinkStyle} to="/gallery"> 
                            <ColorButton>{t("gallery.viewAll")}</ColorButton>
                        </NavLink>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
          
            
        </>
    );
}

export default Gallery;