import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

const containerStyle = {
    height: '100%',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 20,
    borderRadius: 10
}
const titleStyle = {
    fontFamily:'Poppins',
    fontSize: 30,
    fontWeight: 700, 
    color: '#231F1F',
}
const subTitleStyle = {
    fontFamily:'Poppins',
    fontSize: 25,
    fontWeight: 700, 
    color: '#231F1F',
}
const spotifyStyle = {
    backgroundColor: 'lightgrey',
    height: '100%',
    width: '100%',
}
const youtubeStyle = {
    backgroundColor: 'lightgrey',
    width: '100%',
}
const middleGridStyle = {
    backgroundColor: 'lightgrey',
    height: '100%',
    width: '100%',
    paddingBottom: 15,    
}

export default function ArtistAlbumCardSmall({sanatciAdi, albumAdi, spofityLinki, youtubeLinki}) {
  return (
    <>
        <Card style={containerStyle}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width:'100%', }}>
                <Box style={spotifyStyle}>
                    <iframe title="spotify" src={spofityLinki} width="100%" height="100px" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" borderRadius="0px" ></iframe>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}  style={middleGridStyle}>
                    <Typography style={titleStyle}>{sanatciAdi}</Typography>
                    <Typography style={subTitleStyle}>{albumAdi}</Typography>
                </Box>
                <Box style={youtubeStyle}>
                    <iframe width="100%" height="250px" src={youtubeLinki} title="YouTube video player"></iframe>
                </Box>
            </Box>
        </Card>
    </>
  )
}
