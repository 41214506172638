import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import ticketIcon from './images/ticket.svg'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#782166',
    '&:hover': {
      backgroundColor: '#782166'
    },
    width: 100,
    borderRadius: 0,
    height: 35,
    fontSize:10,
    fontFamily:'Poppins',
    fontWeight: 300,
    display:'flex',
    bottom: 0,
    top: 'auto',
    position: 'absolute',
    left: 100,

  }));

  const ColorButton2 = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#4D2876',
    '&:hover': {
      backgroundColor: '#4D2876'
    },
    width: 100,
    borderRadius: 0,
    height: 35,
    fontSize:10,
    fontFamily:'Poppins',
    fontWeight: 300,
    display:'flex',
    bottom: 0,
    top: 'auto',
    position: 'absolute',
    left: 0,

  }));

  const ColorButton3 = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#845db0',
    '&:hover': {
      backgroundColor: '#845db0'
    },
    width: 130,
    borderRadius: 0,
    height: 35,
    fontSize:10,
    fontFamily:'Poppins',
    fontWeight: 300,
    display:'flex',
    bottom: 'auto',
    top: 8,
    position: 'absolute',
    left: 'auto',
    right: 1,

  }));

  const imageStyle = {
    backgroundColor: 'grey',
    width: 300,
    //maxWidth: 350,
    height: 300,
    position: 'relative'
  }
  const containerStyle = {
    backgroundColor:'lightgrey',
    width: '100%',
    height: '100%',
    marginTop:10,
    marginBottom:10,
  }

  const typeTextStyle={
    fontFamily:'Poppins',
    fontWeight: 500,
    margin:0,
    textAlign: "left",
    color: 'black',
}
  const dateTextStyle={
    fontFamily:'Poppins',
    fontWeight: 800,
    margin:0,
    textAlign: "left",
    color: 'black',
    fontSize: 23
}  
const titleStyle={
  fontFamily:'Poppins',
  fontWeight: 300,
  margin:0,
  color: 'black',
  textAlign: "left",
  fontSize: 40,
  lineHeight: "47px",
  paddingBottom: 0,
  paddingTop: 8

}   
const openingStyle={
  fontFamily:'Poppins',
  fontWeight: 300,
  margin:0,
  color: 'black',
  textAlign: "left",
  fontSize: 23,    
  lineHeight: "25px",
  paddingBottom: 8,
  paddingTop: 0
}   
  const placeTextStyle={
    fontFamily:'Poppins',
    fontWeight: 400,
    margin:0,
    color: 'black',
    textAlign: "left",
}  
  const textStyle={
    fontFamily:'Poppins',
    fontWeight: 600,
    margin:0,
    color: 'black',
    fontSize: 12,
    textAlign: "left",

}




export default function ConcertCardRight({eventName, date, artistName, location, text, coverImage, buyLink, openingArtist}) {
  return (
    <>
        <div style={containerStyle}>

            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" >
                <Grid item style={{background:'lightgrey', width: '70%', height: '100%', paddingRight: 15, paddingLeft: 15, paddingTop:7, paddingBottom: 5}}>
                    <Grid container direction="column" justifyContent="center" alignItems="flex-start" style={{position: 'relative'}}>
                        {/* <ColorButton3> TÜM TARİHLER <CalendarMonthIcon style={{paddingLeft:5, fontSize: 30}}/></ColorButton3>                             */}
                        <Grid item>
                            <Typography style={typeTextStyle}>{eventName}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={dateTextStyle}>{date}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={titleStyle}>{artistName}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={openingStyle}>{openingArtist}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={placeTextStyle}>{location}</Typography>
                        </Grid>
                        <Grid item style={{width: '75%'}}>
                            <Typography style={textStyle}>{text}</Typography>
                        </Grid>                        
                    </Grid>
                </Grid>
                <Grid item style={{ height: '100%', position:'relative'}}>
                        <img src={coverImage}  style={imageStyle}/>
                        {buyLink ?                         
                        <Grid container style={{position: 'absolute'}}>
                          <ColorButton href={buyLink}> DETAYLI BİLGİ</ColorButton>                            
                          <ColorButton2 href={buyLink}>BİLET AL<img src={ticketIcon} alt='ticket' style={{width:18, paddingLeft: 3}}/></ColorButton2>
                        </Grid> : ''}

                </Grid>
            </Grid>
          
        </div>
    </>
  )
}
