import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import OnHoverScrollContainer from "./CustomScrollDiv";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import trt2_1 from './images/AMV/TRT_2_1.blm.jpg';
import trt2_2 from './images/AMV/TRT_2_2.blm.jpg';
import trt2_3 from './images/AMV/TRT_2_3.blm.jpg';
import trt2_4 from './images/AMV/TRT_2_4.blm.jpg';
import trt2_5 from './images/AMV/TRT_2_5.blm.jpg';
import trt2_6 from './images/AMV/TRT_2_6.blm.jpg';
import trt2_7 from './images/AMV/TRT_2_7.blm.jpg';
import trt2_8 from './images/AMV/TRT_2_8.blm.jpg';
import trt2_9 from './images/AMV/TRT_2_9.blm.jpg';
import trt2_10 from './images/AMV/TRT_2_10.blm.jpg';
import trt2_11 from './images/AMV/TRT_2_11.blm.jpg';
import trt2_12 from './images/AMV/TRT_2_12.blm.jpg';
import trt2_13 from './images/AMV/TRT_2_13.blm.jpg';
import trt2_14 from './images/AMV/TRT_2_14.blm.jpg';
import trt2_15 from './images/AMV/TRT_2_15.blm.jpg';
import trt2_16 from './images/AMV/TRT_2_16.blm.jpg';
import trt2_17 from './images/AMV/TRT_2_17.blm.jpg';
import trt2_18 from './images/AMV/TRT_2_18.blm.jpg';
import trt2_19 from './images/AMV/TRT_2_19.blm.jpg';
import trt2_20 from './images/AMV/TRT_2_20.blm.jpg';
import trt2_21 from './images/AMV/TRT_2_21.blm.jpg';
import trt2_22 from './images/AMV/TRT_2_22.blm.jpg';
import trt2_23 from './images/AMV/TRT_2_23.blm.jpg';
import trt2_24 from './images/AMV/TRT_2_24.blm.jpg';
import trt2_25 from './images/AMV/TRT_2_25.blm.jpg';
import trt2_26 from './images/AMV/TRT_2_26.blm.jpg';
import trt2_27 from './images/AMV/TRT_2_27.blm.jpg';
import trt2_28 from './images/AMV/TRT_2_28.blm.jpg';
import trt2_29 from './images/AMV/TRT_2_29.blm.jpg';
import trt2_30 from './images/AMV/TRT_2_30.blm.jpg';
import trt2_31 from './images/AMV/TRT_2_31.blm.jpg';
import trt2_32 from './images/AMV/TRT_2_32.blm.jpg';
import trt2_33 from './images/AMV/TRT_2_33.blm.jpg';
import trt2_34 from './images/AMV/TRT_2_34.blm.jpg';
import trt2_35 from './images/AMV/TRT_2_35.blm.jpg';
import trt2_36 from './images/AMV/TRT_2_39.blm.jpg';
import trt2_37 from './images/AMV/Satin.jpg';
import trt2_38 from './images/AMV/Emin.jpg';

import trtturk_1 from './images/AMV/TRT_türk_1.blm.jpg';
import trtturk_2 from './images/AMV/TRT_türk_2.blm.jpg';
import trtturk_3 from './images/AMV/TRT_türk_3.blm.jpg';
import trtturk_4 from './images/AMV/TRT_türk_4.blm.jpg';
import trtturk_5 from './images/AMV/TRT_türk_5.blm.jpg';
import trtturk_6 from './images/AMV/TRT_türk_6.blm.jpg';
import trtturk_7 from './images/AMV/TRT_türk_7.blm.jpg';
import trtturk_8 from './images/AMV/TRT_türk_8.blm.jpg';
import trtturk_9 from './images/AMV/TRT_türk_9.blm.jpg';
import trtturk_10 from './images/AMV/TRT_türk_10.blm.jpg';
import trtturk_11 from './images/AMV/TRT_türk_11.blm.jpg';
import trtturk_12 from './images/AMV/TRT_türk_12.blm.jpg';
import trtturk_13 from './images/AMV/TRT_türk_13.blm.jpg';

const episodes = [
    { image: trtturk_1, guestName: "Peter Gabriel", tvChannel: "Londra"},
    { image: trtturk_2, guestName: "Jermaine Jackson", tvChannel: "Los Angeles"},
    { image: trtturk_3, guestName: "Enrico Macias", tvChannel: "Paris"},
    { image: trtturk_4, guestName: "Goran Bregovic", tvChannel: "Belgrad"},
    { image: trtturk_5, guestName: "Boney M.", tvChannel: "Berlin"},
    { image: trtturk_6, guestName: "Ian Gillan", tvChannel: "Lizbon"},
    { image: trtturk_7, guestName: "Steven Seagal", tvChannel: "Phoenix"},
    { image: trtturk_8, guestName: "Haris Alexiou", tvChannel: "Atina"},
    { image: trtturk_9, guestName: "Rachid Taha", tvChannel: "Paris"},
    { image: trtturk_10, guestName: "Albano", tvChannel: "Brindisi "},
    { image: trtturk_11, guestName: "Johnny Logan", tvChannel: "Dublin"},
    { image: trtturk_12, guestName: "Dany Brillant", tvChannel: "Paris"},
    { image: trtturk_13, guestName: "Jools Holland", tvChannel: "Londra"},
    { image: trt2_1, guestName: "Ara Malikian", tvChannel: "Madrid"},
    { image: trt2_2, guestName: "Mark Eliyahu", tvChannel: "Tel Aviv"},
    { image: trt2_3, guestName: "Gheorghe Zamfir", tvChannel: "Bükreş"},
    { image: trt2_4, guestName: "Natacha Atlas", tvChannel: "Toulouse"},
    { image: trt2_5, guestName: "Monica Molina", tvChannel: "Roma"},
    { image: trt2_6, guestName: "Loreena McKennitt", tvChannel: "Toronto"},
    { image: trt2_7, guestName: "Paco Pena", tvChannel: "Cordoba"},
    { image: trt2_8, guestName: "Antonis Remos", tvChannel: "Atina"},
    { image: trt2_9, guestName: "Havasi", tvChannel: "Budapeşte"},
    { image: trt2_10, guestName: "Jordi Savall", tvChannel: "Barselona"},
    { image: trt2_11, guestName: "Evgeny Grinko", tvChannel: "Moskova"},
    { image: trt2_12, guestName: "Saxon", tvChannel: "Leeds"},
    { image: trt2_13, guestName: "Lena Chamamyan", tvChannel: "Paris"},
    { image: trt2_14, guestName: "Peter Bence", tvChannel: "Berlin"},
    { image: trt2_15, guestName: "David Garrett", tvChannel: "Frankfurt"},
    { image: trt2_16, guestName: "Sharon Kovacs", tvChannel: "Eindhoven"},
    { image: trt2_17, guestName: "Hindi Zahra", tvChannel: "Brüksel"},
    { image: trt2_18, guestName: "Luz Casal", tvChannel: "Sevilla"},
    { image: trt2_19, guestName: "Weather Girls", tvChannel: "Köln"},
    { image: trt2_20, guestName: "Glykeria", tvChannel: "Selanik"},
    { image: trt2_21, guestName: "Cecilia Krull", tvChannel: "Toledo"},
    { image: trt2_22, guestName: "Chico&The Gypsies", tvChannel: "Arles"},
    { image: trt2_23, guestName: "Amira Medunjanin", tvChannel: "Saraybosna"},
    { image: trt2_24, guestName: "Dhafer Youssef", tvChannel: "Viyana"},
    { image: trt2_25, guestName: "Eivør", tvChannel: "Faroe Adaları"},
    { image: trt2_26, guestName: "Toni Cetinski", tvChannel: "Zagreb"},
    { image: trt2_27, guestName: "Barcelona Gipsy Balkan Orchestra", tvChannel: "Girona"},
    { image: trt2_28, guestName: "Soen", tvChannel: "Göteborg"},
    { image: trt2_29, guestName: "Buika", tvChannel: "Madrid"},
    { image: trt2_30, guestName: "Epica", tvChannel: "Amsterdam"},
    { image: trt2_31, guestName: "Jay Jay Johanson", tvChannel: "Stockholm"},
    { image: trt2_32, guestName: "Anneke van Giersbergen", tvChannel: "Utrecht"},
    { image: trt2_33, guestName: "Faun", tvChannel: "Münih"},
    { image: trt2_34, guestName: "Alessandro Safina", tvChannel: "Milano"},
    { image: trt2_35, guestName: "Souad Massi", tvChannel: "Bordeaux"},
    { image: trt2_37, guestName: "Satin", tvChannel: "KKTC"},
    { image: trt2_38, guestName: "Emin", tvChannel: "Bakü"},
    { image: trt2_36, guestName: "Oscar Anton", tvChannel: "Paris"},


];
const ContainerStyle = {
    marginTop: 30,
    marginBottom: 20,
    width: '100%',
    padding: 0,
    height: 800,
    
}
const GridStyle = {
    margin:0,
    width: '100%',
    paddingRight: 15,
}
const titleStyle={
    fontFamily:'Poppins',
    fontWeight: 500,
    marginBottom:0,
    fontSize: 15,
    color: 'white'
  }
  const dateStyle={
    fontFamily:'Poppins',
    fontWeight: 300,
    color: 'white',
    fontSize: 12,
    marginBottom:0,

  }

const TelevisionEventBox = (episodes,index) => {
    return (
  
      <>
        <Grid key={index} item sx={{pt:"10px !important"}}>
          <Card sx={{ width:270, borderRadius:0}}>
              <CardMedia component="img" title="ArtistImage" image={episodes.image} height="200" width="270" />
              <CardContent sx={{backgroundColor: '#522875', height: "100%", p: "8px !important"}}>
                  <Typography style={titleStyle}>
                      {episodes.guestName}
                  </Typography>
                  <Typography style={dateStyle}>
                      {episodes.tvChannel}
                  </Typography>
              </CardContent>
          </Card>
        </Grid>

      </>
    )
  }

export default function TelevisionEventBoxes() {
  return (
    <div style={{paddingBottom: 20}}>
        <Container style={ContainerStyle} maxWidth="xxl">
            <OnHoverScrollContainer>
                <Grid container  direction="row" justifyContent="space-between" alignItems="center" style={GridStyle}>                   
                    {episodes.map(TelevisionEventBox)} 
                </Grid>
            </OnHoverScrollContainer>       
        </Container>

    </div>
  )
}
