import React, { useState }  from 'react'
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Drawer,Paper,Grid,Typography,Button } from "@mui/material";
import {List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import {useTranslation} from "react-i18next";


const MenuDrawers = () => {


    const {t, i18n}= useTranslation();

    const clickHandle = lang => {
      i18n.changeLanguage(lang)
    
    }
    const changeLang = () => {
      if (localStorage.getItem("selectedLanguage") === 'en') {
        localStorage.setItem("selectedLanguage","tr");
      }
      else if (localStorage.getItem("selectedLanguage") === 'tr') {
        localStorage.setItem("selectedLanguage","en");
      }
    }



    const pages = [
        { title: t("header.artist"), link: "/artists"},
        { title: t("header.concert"), link: "/concerts"},
        { title: t("header.production") + " | " + t("header.music"), link: "/music"},
        { title: t("header.production") + " | " + t("header.television"), link: "/television"},
        { title: t("header.aboutUs"), link: "/us"},
        { title: t("header.contact"), link: "/contact"},
    ]
    const [openDrawer, setOpenDrawer] = useState(false);
   
    const drawerStyle = {
        height: '100%',
        background: 'linear-gradient(60deg, #492978 0%, #7A2165 100%)',        
    }

    const drawerSize ={
        width: '60%',
    }

    const listStyle = {
        margin: 10,
        top:'30%',
        
    }

    var menuIconStyle = {
        color : 'white',
        fontSize: 33,
        padding:0,  
      };

    var navlinkStyle = {
        textDecoration: "none"
      };
      var langButtonStyle = {     
        fontFamily:'Poppins', 
        color:'white',
        fontSize: 15,
        fontWeight: 300,   
      };
    return (
       
    <React.Fragment>
        <Drawer style={drawerSize} anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <Paper style={drawerStyle} >
                <Grid container spacing={5} justifyContent="center" alignItems="center" sx={{pt:2}}>
                    <Grid item>
                        <Button onClick={() => clickHandle('tr')}>
                            <Typography style={langButtonStyle}> TR </Typography>                         
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={() => clickHandle('en')}>
                            <Typography style={langButtonStyle}> EN </Typography>                         
                        </Button>
                    </Grid>
                </Grid>
                <List style={listStyle} >
                {pages.map((page, index) => (
                    <ListItemButton key={index} >
                        <NavLink to={page.link} style={navlinkStyle} onClick={() => setOpenDrawer(false)}>
                            <ListItemIcon>                    
                                <ListItemText sx={{color: 'white'}} >{page.title}</ListItemText>
                            </ListItemIcon>
                        </NavLink>                
                    </ListItemButton>
                ))}
                
                </List>
        </Paper>
        </Drawer>
        <IconButton  sx={{ color: "white", marginLeft: "auto", justifyContent: "flex-end" }} onClick={() => setOpenDrawer(!openDrawer)}>
            <MenuIcon color="white" style={menuIconStyle} />
        </IconButton>
    </React.Fragment>
    )
}

export default MenuDrawers