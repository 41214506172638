import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeaderLarge from "./HeaderLarge";
import HeaderSmall from "./HeaderSmall";
import Home from "./Home";
import ContactPage from "./ContactPage";
import Artists from "./Artists";
import Television from "./Television";
import Music from "./Music";
import Concerts from "./Concerts";
import Us from "./Us";
import Footer from "./Footer";
import GalleryPage from "./GalleryPage";
import TelevisionDetail from "./TelevisionDetail";
import { useMediaQuery, useTheme, } from "@mui/material";
import { useEffect } from 'react';
   

function App() {

  const theme = useTheme();
  const isDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  if (localStorage.getItem("selectedLanguage") === null) {
    localStorage.setItem("selectedLanguage","tr");
  }
  return (
    <>
    
    {isDownMD ? (                  
      <HeaderSmall/>                  
    ):(
      <HeaderLarge/>
    )}    
      <Routes basename="musicarium.live">
        <Route path="/artists" element={<Artists/>}/>
        <Route path="/concerts" element={<Concerts/>}/>
        <Route path="/music" element={<Music/>}/>
        <Route path="/television" element={<Television/>}/>
        <Route path="/television_detail" element={<TelevisionDetail/>}/>
        <Route path="/us" element={<Us/>}/>
        <Route path="/gallery" element={<GalleryPage/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="/" element={<Home/>}/>
      </Routes>
      <Footer/>


    </>
  );
}

export default App;
