import React from 'react'
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const titleStyle={
    fontFamily:'Poppins',
    fontWeight: 600,
    padding:10,
    margin:0,
    color: 'white',

}
export default function GalleryCard({imageHere,imageText}) {
  return (
    <div>        
        <Card sx={{ maxWidth: 500, width: '100%', borderRadius: 0, mb:5,}}>
            <img src={imageHere} height={"350"} width={"500"}/>
            <CardContent sx={{background:'#46328F', p:"0px !important", display: 'flex',  justifyContent: 'flex-start', alignItems:'center' }}>
            <Typography style={titleStyle}>
                {imageText}
            </Typography>

            </CardContent>
        </Card>
</div>
  )
}
