import React, { useRef } from "react";
import { useMediaQuery, useTheme, } from "@mui/material";
import { Grid,Container,Typography,Button,Paper,TextField } from "@mui/material";
import backLayer from './images/contact_backlayer.png'
import { purple } from '@mui/material/colors';
import { alpha, styled } from '@mui/material/styles'
;
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {useTranslation} from "react-i18next";




import "./ContactForm.css";
import emailjs from '@emailjs/browser';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

function ContactForm() {

    const [open, setOpen] = React.useState(false);
    const {t, i18n}= useTranslation();

    const handleClick = () => {
      setOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
      setOpenFail(false);

    };
    const [openFail, setOpenFail] = React.useState(false);



    const theme = useTheme();
    const isDownMD = useMediaQuery(theme.breakpoints.down("md"));

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[700],
        '&:hover': {
          backgroundColor: purple[800],
        },
        width: 150,
        borderRadius: 12,
        height: 35,
        fontSize:13,
        fontFamily:'Poppins',
        fontWeight: 700,
      }));

    const imageBackground = {
        backgroundImage: "url("+ backLayer +")",
        height:'auto',    
       };

       const titleStyle = {
        color: "#46328F",
        fontFamily:'Poppins',
        fontWeight: 800,
        paddingBottom:30,
        fontSize:38,
    }

    const textFieldStyle = {
        '& .MuiFilledInput-root': {
            border: '1px solid #e2e2e1',
            overflow: 'hidden',
            borderRadius: 4,
            backgroundColor: 'white'

    }}

    const RedditTextField = styled((props) => (
        <TextField InputProps={{ disableUnderline: true }} {...props} />
      ))(({ theme }) => ({
        '& .css-e4w4as-MuiFormLabel-root-MuiInputLabel-root': {
            color: 'rgba(101, 45, 144, 0.7)',
            fontFamily:'Poppins',
            fontWeight: 300,

          },
        '& label.Mui-focused': {
            color: 'rgba(101, 45, 144, 0.7)',
            fontFamily:'Poppins',
            fontWeight: 300,

          },
        '& .MuiFilledInput-root': {
          border: '1px solid #e2e2e1',
          
          color: 'purple',
          overflow: 'hidden',
          borderRadius: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.39)',
          borderColor: 'rgba(101, 45, 144, 0.4)',
          transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
          ]),
          '&.Mui-focused': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: 'rgba(101, 45, 144, 0.4)',

          },
        },
      }));

      const SERVICE_ID = "service_8rhfwfi";
      const TEMPLATE_ID = "template_gt8d6o4";
      const USER_ID = "GJmkZfBsLlTA7iPM3";

      const form = useRef();

      const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID)
          .then((result) => {
              console.log(result.text);
              setOpen(true);
          }, (error) => {
              console.log(error.text);
              setOpenFail(true);
          });
      };
      

    return (

        <>

            <Paper style={imageBackground} sx={{pb:10, pt:10}}>
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{pb:0,pt:5}}>
                    <Grid item>
                        <Typography style={titleStyle}> {t("contactUs.contactForm")} </Typography>
                    </Grid>
                </Grid>
                {isDownMD ? ( 
                    <form ref={form} onSubmit={sendEmail} style={{width:'100%'}}>

                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{width:'100%', maxWidth:'3000px'}}>
                    <Grid item sx={{width:'80%'}}>                    
                        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={0} sx={{width:'100%', maxWidth:'3000px'}} >                        
                            <Grid item sx={{width:'100%',pb:1 }}>
                                <input type="text" class="form__input" id="name" placeholder={t("contactUs.nameSurname")} required="" name="from_name"  />
                            </Grid>
                            <Grid item sx={{width:'100%'}}>
                                <input type="text" class="form__input" id="e-posta" placeholder={t("contactUs.email")} required="" name="from_email"  />
                            </Grid>    
                        </Grid>                                           
                    </Grid>       
                    <Grid item sx={{width:'80%'}}>
                        <textarea name="message" placeholder={t("contactUs.message")}  class="form__input"/>
                    </Grid>
                    <Grid item>
                        <ColorButton variant="contained" onClick={sendEmail}>{t("contactUs.send")} </ColorButton>
                    </Grid>  
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {t("contactUs.success")}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openFail} autoHideDuration={6000} onClose={handleClose}>
                        <Alert  onClose={handleClose} severity="error" sx={{ width: '100%' }}>{t("contactUs.fail")}</Alert>
                    </Snackbar>      
                </Grid>       
                </form>

                            ):(
                    <form ref={form} onSubmit={sendEmail} style={{width:'100%'}}>

                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2} sx={{width:'100%', maxWidth:'3000px'}}>
                    <Grid item sx={{width:'50%'}}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0} sx={{width:'100%', maxWidth:'3000px'}}>
                            <Grid item sx={{width:'50%', pr:1}}>
                            <input type="text" class="form__input" id="name" placeholder={t("contactUs.nameSurname")} required="" name="from_name"  />
                          </Grid>
                            <Grid item sx={{width:'50%', pl:1}}>
                            <input type="text" class="form__input" id="e-posta" placeholder={t("contactUs.email")}  required="" name="from_email"  />
                          </Grid>                                                         
                        </Grid>
                    </Grid> 
                    <Grid item sx={{width:'50%'}}>
                        <textarea name="message" placeholder={t("contactUs.message")} class="form__input"/>
                    </Grid>
                    <Grid item>
                        <ColorButton variant="contained" onClick={sendEmail}>{t("contactUs.send")}</ColorButton>
                    </Grid>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {t("contactUs.success")}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openFail} autoHideDuration={6000} onClose={handleClose}>
                        <Alert  onClose={handleClose} severity="error" sx={{ width: '100%' }}>{t("contactUs.fail")}!</Alert>
                    </Snackbar>
                    {/* <Grid item sx={{width:'50%'}}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0} sx={{width:'100%', maxWidth:'3000px'}}>
                            <Grid item sx={{width:'50%', pr:1}}>
                                <RedditTextField fullWidth id="from_name" label="ad, soyad" variant="filled"/>

                            </Grid>
                            <Grid item sx={{width:'50%', pl:1}}>
                                <RedditTextField fullWidth id="from_email" label="e-posta" variant="filled" />
                            </Grid>                                                         
                        </Grid>
                    </Grid> 
                    <Grid item sx={{width:'50%'}}>
                        <RedditTextField fullWidth id="message" label="mesaj" multiline rows={4} variant="filled" />
                    </Grid>
                    <Grid item>
                        <ColorButton variant="contained" onClick={sendEmail}>GÖNDER</ColorButton>
                    </Grid> */}
                    
                </Grid>  
                </form>
                )}                                         
            </Paper>

        </>
    );
}

export default ContactForm;