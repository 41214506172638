import React from 'react';

const language = {
    'tr': {
        'test': 'Türkçe'
    },
    'en': {
        'test': 'English'
    }
}

export default language;