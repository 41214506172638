import React from 'react'
import TelevisionEventBoxes from './TelevisionEventBoxes'
import TelevisionCardRightNoButton from './TelevisionCardRightNoButton'
import TelevisionCardSmallNoButton from './TelevisionCardSmallNoButton'

import Container from '@mui/material/Container'
import coverImage from './images/AMV/TRT_2_1.blm.jpg';
import { useMediaQuery, useTheme, } from "@mui/material";

export default function TelevisionDetail() {

  const theme = useTheme();
  const isDownMD = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    
    <div style={{backgroundColor: '#E3E4E4'}}>

    {isDownMD ? ( 
      <Container maxWidth="lg">
  
      <TelevisionCardSmallNoButton title={"ARAMIZDA MÜZİK VAR"} text={"Her bölümde farklı bir ülkede farklı bir şehirde karşımıza çıkan Fuat Güner, dünyaca ünlü müzisyenlerle bir araya geliyor. "} subTitle={"TRT 2 - TRT TÜRK"} link={"https://www.trtizle.com/programlar/aramizda-muzik-var"} image={coverImage}/>
      <TelevisionEventBoxes/>

    </Container>
    ):(
      <Container maxWidth="lg">
  
        <TelevisionCardRightNoButton title={"ARAMIZDA MÜZİK VAR"} text={"Her bölümde farklı bir ülkede farklı bir şehirde karşımıza çıkan Fuat Güner, dünyaca ünlü müzisyenlerle bir araya geliyor. "} subTitle={"TRT 2 - TRT TÜRK"} link={"https://www.trtizle.com/programlar/aramizda-muzik-var"} image={coverImage}/>
        <TelevisionEventBoxes/>

      </Container>
    )}


    </div>

  )
}
