import React from 'react'
import GalleryList from "./GalleryList";
import ArtistSliderComp from "./ArtistSliderComp";
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography';
import ArtistCard from './ArtistCard'
import Grid from '@mui/material/Grid'
import OnHoverScrollContainer from "./CustomScrollDiv";


const textStyle = {
    color: '#4C2876',
    fontFamily:'Poppins',
    fontSize: 25,
    fontWeight: 700,
    paddingTop: 30,
    paddingBottom: 30
  }
  const GridStyle = {
    width: '100%',
    paddingRight: 15,
}
  

export default function GalleryPage() {
  return (
    <div>
        <Container maxWidth="lg">

            <Typography style={textStyle}> GALERİ </Typography>
            <ArtistSliderComp/>
            
            <Container maxWidth="lg" style={{paddingTop: 50, paddingRight:"0px !important", paddingLeft:"0px !important", hegiht: 300}}>
                <GalleryList/>
            </Container>
        </Container>

    </div>
  )
}
