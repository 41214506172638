import React from "react"
import { useState } from "react"
import Box from '@mui/material/Box'
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded'
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'


const slideStyles = {
    width: "100%",
    height: "100%",
    borderRadius: "0",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  
  const rightArrowStyles = {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    right: "32px",
    color: "#fff",
    zIndex: 1,
    cursor: "pointer",
    backgroundColor: 'rgba(150, 150, 150, 0.5)',
    fontSize: "30px",
    borderRadius: 0
  };
  
  const leftArrowStyles = {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    left: "32px",
    color: "#fff",
    zIndex: 1,
    cursor: "pointer",
    backgroundColor: 'rgba(150, 150, 150, 0.5)',
    fontSize: "30px",
    borderRadius: 0
  };
  
  const sliderStyles = {
    position: "relative",
    height: "100%",
  };

  const iconStyle = {
    margin: "0px !important",
  }

  const containerOverSliderStyle = {
    display: "flex",
    position: "absolute",
    justifyContent: "flex-start",
    top: "70%",
    
  };
  
  const textOverSliderStyleFirst = {
    fontFamily:'Poppins',
    fontWeight: 800,
    fontSize: 24,
    paddingBottom: 0,
    margin:0,
    color: 'white',
  };
  const textOverSliderStyleSecond = {
    fontFamily:'Poppins',
    fontWeight: 400,
    fontSize: 18,
    margin:0,
    color: 'white',
  };


const ArtistSlider = ({ slides }) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const goToPrevious = () => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
    const goToNext = () => {
      const isLastSlide = currentIndex === slides.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };
    const slideStylesWidthBackground = {
      ...slideStyles,
      backgroundImage: `url(${slides[currentIndex].url})`,
    };
    // const goToSlide = (slideIndex) => {
    //   setCurrentIndex(slideIndex);
    // };


    return (

        <div style={sliderStyles}>
            <div>
                <IconButton style={leftArrowStyles} onClick={goToPrevious}>
                    <ArrowLeftRoundedIcon style={iconStyle} fontSize="150px"/>
                </IconButton>
                <IconButton style={rightArrowStyles} onClick={goToNext}>
                    <ArrowRightRoundedIcon style={iconStyle} fontSize="150px"/>
                </IconButton>
            </div>
            <Grid container style={containerOverSliderStyle} direction="row" justifyContent="flex-start" alignItems="center"> 
                <Grid item style={{paddingRight: 10}}>
                    <Box style={{background:'#46328F', height:60, width: 60,}}/>
                </Grid>
                <Grid item >
                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item>
                            <div style={textOverSliderStyleFirst}>
                                {slides[currentIndex].title}
                            </div>
                        </Grid>
                        <Grid item >
                            <div style={textOverSliderStyleSecond}>
                            {slides[currentIndex].text}
                            </div>
                        </Grid>
                    </Grid>
                  </Grid>               
            </Grid>
        <div style={slideStylesWidthBackground}></div>
      </div>
    );
}

export default ArtistSlider;