import React from 'react'
import TelevisionCardRight from './TelevisionCardRight'
import TelevisionCardLeft from './TelevisionCardLeft'
import TelevisionCardSmall from './TelevisionCardSmall'

import Container from '@mui/material/Container'
import coverImage from './images/AMV/TRT_2_1.blm.jpg';
import { useMediaQuery, useTheme, } from "@mui/material";
import {useTranslation} from "react-i18next";


function Television() {

  const theme = useTheme();
  const isDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const {t, i18n}= useTranslation();

  return (
    <>

    {isDownMD ? ( 
    <Container maxWidth="lg">

        
      <TelevisionCardSmall title={"ARAMIZDA MÜZİK VAR"} text={t("production.tv.text")} subTitle={"TRT 2 - TRT TÜRK"} link={"https://www.trtizle.com/programlar/aramizda-muzik-var"} image={coverImage} buttonText={t("production.tv.viewAll")}/>
      
    </Container>
    ):(
      <Container maxWidth="lg">

      
        <TelevisionCardRight title={"ARAMIZDA MÜZİK VAR"} text={t("production.tv.text")} subTitle={"TRT 2 - TRT TÜRK"} link={"https://www.trtizle.com/programlar/aramizda-muzik-var"} image={coverImage} buttonText={t("production.tv.viewAll")}/>
      
      </Container>
    )}


    </>
  )
}

export default Television