import React from "react";
import { useState } from "react";
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid'

const slideStyles = {
    width: "100%",
    height: "100%",
    borderRadius: "0",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  
  const rightArrowStyles = {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    right: "32px",
    color: "#fff",
    zIndex: 1,
    cursor: "pointer",
    backgroundColor: 'rgba(150, 150, 150, 0.5)',
    fontSize: "40px",

  };
  
  const leftArrowStyles = {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    left: "32px",
    color: "#fff",
    zIndex: 1,
    cursor: "pointer",
    backgroundColor: 'rgba(150, 150, 150, 0.5)',
    fontSize: "40px",
  };
  
  const sliderStyles = {
    position: "relative",
    height: "100%",
  };

  const iconStyle = {

    margin: "0px !important",
  }

  const dotsContainerStyles = {
    display: "flex",
    position: "absolute",
    justifyContent: "center",
    top: "90%",
  };
  
  const dotStyle = {
    margin: "0 3px",
    cursor: "pointer",
    fontSize: "25px",
    color: 'white',
    
  };
  const buttonStyle = {
    border: 'none',
    width:'100%',
    height:'100%',
    opacity:0,
  }


const BigSlider = ({ slides }) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    // const toUrl = '';
    const goToPrevious = () => {
      const isFirstSlide = currentIndex === 0;
      const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    };
    const goToNext = () => {
      const isLastSlide = currentIndex === slides.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    };
    const goToSlide = (slideIndex) => {
      // const toUrl= slides.link;
      setCurrentIndex(slideIndex);
    };
    const slideStylesWidthBackground = {
      ...slideStyles,
      backgroundImage: `url(${slides[currentIndex].url})`,
      // backgroundImage: `url(../images/slider1_big_tr.jpg)`,

    };

    return (

      <div style={sliderStyles}>
            <div>
                <IconButton style={leftArrowStyles} onClick={goToPrevious}>
                    <ArrowLeftRoundedIcon style={iconStyle} fontSize="100px"/>
                </IconButton>
                <IconButton style={rightArrowStyles} onClick={goToNext}>
                    <ArrowRightRoundedIcon style={iconStyle} fontSize="100px"/>
                </IconButton>
            </div>
            <Grid container style={dotsContainerStyles}>
  
                {slides.map((slide, slideIndex) => (
                <div
                    style={dotStyle}
                    key={slideIndex}
                    onClick={() => goToSlide(slideIndex)}
                >
                    ●
                </div>
                ))}

            </Grid>
                        
        <div style={slideStylesWidthBackground}>        
          {/* <button style={buttonStyle} href={toUrl}>
          </button>    */}
        </div>


      </div>
    );
}

export default BigSlider;