import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import YouTubeIcon from '@mui/icons-material/YouTube';
import IconButton from '@mui/material/IconButton';


const cardStyle = {
    height: '100%', 
    backgroundColor: '#522875',
    width: '100%'
}
const textStyle = {
    color:'white',
    fontFamily:'Poppins',
    paddingBottom: 10,
}
const imageStyle = {
    backgroundColor: 'grey',
    width: '100%',
    height: '100%',
    position: 'relative'
  }

export default function TelevisionCardRightNoButton({title, text, subTitle, subText, link, image}) {
  return (
    <div style={{paddingTop: 30, paddingBottom: 20}}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={cardStyle}>
            <Grid item sx={{height: '100%', width: '35%', m:1,}}>
                <Box sx={{height: 300, width: '100%'}}>
                <img src={image}  style={imageStyle}/>
                </Box>
            </Grid>
            <Grid item sx={{height: '100%', width:'60%', m:1,}}>
                <Grid container direction="column" justifyContent="space-between" alignItems="flex-start" sx={{height:'100%'}}>
                    <Grid item sx={{height: '100%'}}>
                        <Typography variant="h4" style={textStyle} sx={{pb:"20px !important", pt:"10px"}}>{title}</Typography>
                        <Typography variant="body2" style={textStyle} sx={{pb:"30px !important"}}>{text}</Typography>
                        <Typography variant="h6" style={textStyle} sx={{pb:"5px !important"}}>{subTitle}</Typography>
                        <Typography variant="body2" style={textStyle}>{subText}</Typography>
                    </Grid>
                    <Grid item width="100%" >
                        <Grid container justifyContent="flex-end" direction="row" alignItems="center">                            
                            <Grid item>
                                <IconButton href={link}>
                                    <YouTubeIcon sx={{color: 'white', fontSize: 60,p:0,m:0}}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                      </Grid>

              
                </Grid>



            </Grid>
        </Grid>
    </div>
  )
}
