import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import artist2 from './images/Artists/exc_fuat_guner.png';
import artist3 from './images/Artists/exc_monica_molina.png';
import artist4 from './images/Artists/bp_omima.png';


const exArtist = [
    {photo: artist3, name:"Monica Molina"},
    {photo: artist2, name:"Fuat Güner"},
    // {photo: artist4, name:"Omima"},

];
const titleStyle={
    fontFamily:'Poppins',
    fontWeight: 600,
    padding:10,
    margin:0,
    color: 'white',

}
const ContainerStyle = {
    
    marginTop: 30,
    marginBottom: 20,
    width: '100%',
    padding: 0,
    height: '100%',
    
}
const GridStyle = {
    margin:0,
    width: '100%',
    paddingRight: 15,
}


const ArtistCardExclusive = ( exArtist,index ) => {
    return (
      <>
          <Grid key={index} item sx={{pt:"10px !important", pr: "5px"}} >
              <Card sx={{ width: 400, height: 310, borderRadius: 0, mb:5,}}>
                  <img src={exArtist.photo} width={'100%'} alt={exArtist.name}/>
                  <CardContent sx={{background:'#46328F', p:"0px !important", display: 'flex',  justifyContent: 'center' }}>
                    <Typography style={titleStyle}>
                        {exArtist.name}
                    </Typography>
                  </CardContent>
              </Card>
          </Grid>
  
      </>
    )
  }

export default function ArtistListExclusiveComp() {
  return (
    <div style={{paddingBottom: 20, paddingLeft: 10, paddingRight: 10}}>
        <Container style={ContainerStyle} maxWidth="xxl">
                <Grid container  direction="row" justifyContent="space-evenly" alignItems="flex-start" style={GridStyle} spacing={0}>
                    {exArtist.map(ArtistCardExclusive)}
                </Grid>
        </Container>

    </div>
  )
}
