import React from "react";
import { Grid,Container,Typography,Button } from "@mui/material";
import EventCarousel from './EventCarousel'
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import { NavLink } from "react-router-dom";
import {useTranslation} from "react-i18next";

    {/* <Box sx={{ width: 300, height: 300, backgroundColor: 'blue', '&:hover': { width: 500, height: 500, backgroundColor: 'primary.secondry', opacity: [0.9, 0.8, 0.7],},}} /> */}


function Featured() {

    const {t, i18n}= useTranslation();

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: purple[700],
        '&:hover': {
          backgroundColor: purple[800],
        },
        width: 150,
        borderRadius: 12,
        height: 35,
        fontSize:13,
        fontFamily:'Poppins',
        fontWeight: 700,
      }));

    const containerStyle = {
        maxWidth: '100%',
        padding:10,
        paddingBottom: 5,
        paddingTop:30
    }

    const titleStyle = {
        color: "#46328F",
        fontFamily:'Poppins',
        fontWeight: 800,
        paddingBottom:0,
        fontSize:38,
    }
    const textStyle = {
        color: "#46328F",
        fontFamily:'Poppins',
        fontWeight: 600,
        paddingBottom:5,
        fontSize:15,

    }
    var navlinkStyle = {
        textDecoration: "none"
      };
    return (
        
        <>
            <Container sx={{background:'#E4E4E4',}} style={containerStyle}>
                <Grid container direction="column" sx={{ml:5, mr:5, width: 'auto'}}>
                    <Grid item>                
                        <Typography variant="h5" style={titleStyle}>{t("featured.featured")}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" style={textStyle}>{t("featured.toSeeAllEvents")}</Typography>
                    </Grid>
                    <Grid item>
                        <NavLink to="/concerts" style={navlinkStyle}>
                            <ColorButton variant="contained">{t("featured.click")}</ColorButton>
                        </NavLink>
                    </Grid>
                </Grid>
                <EventCarousel/>                                
            </Container>
        </>
    );
}

export default Featured;

