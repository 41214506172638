import React, { useState } from 'react';
import MenuDrawers from "./MenuDrawers";
import { AppBar, Toolbar, Tabs, Tab, IconButton, Button, Grid, MenuItem, Popover, Divider,Typography,Link } from "@mui/material";
import { useMediaQuery, useTheme, Container, } from "@mui/material";
import Mscrm_logo from './images/mscrm_Logo.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { NavLink } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//const pages = ['Products', 'Pricing', 'Blog'];


const HeaderSmall = () => {

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

const {t, i18n}= useTranslation();

const clickHandle = lang => {
  i18n.changeLanguage(lang)

}
const changeLang = () => {
  if (localStorage.getItem("selectedLanguage") === 'en') {
    localStorage.setItem("selectedLanguage","tr");
  }
  else if (localStorage.getItem("selectedLanguage") === 'tr') {
    localStorage.setItem("selectedLanguage","en");
  }
}



  const [value, setValue] = useState();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const isDownMD = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //Styles After That

  var logoStyle = {      
    width: 200,
    paddingRight: 0,
  };
  var headerStyle = {
    background: 'linear-gradient(30deg, #492978 0%, #7A2165 100%)',
    maxWidth: "md",
    position: "absolute !important"

  };
  var tabStyle = {     
    fontFamily:'Poppins', 
    color:'white',
    fontSize: 18,
    fontWeight: 300,
    paddingLeft: 20,
    paddingRight: 20,
    
  };
  var navButtonStyle = {     
    fontFamily:'Poppins', 
    color:'white',
    fontSize: 18,
    fontWeight: 300,   
  };
  var buttonNavStyle={
    paddingLeft: "12px !important",
    paddingRight: "12px !important",
  }
  var iconStyle = {
    color : 'black',
    fontSize: 20,
    padding:0,

  };
  var buttonStyle = {
    background: 'white',
    padding: 0,
    margin: 3,
    height: 30,
    width: 30,
  };
  var menuStyle = {
    fontFamily:'Poppins',
    fontSize: 17,
    fontWeight: 300, 
    backgroundColor: '#90278E',
    color: 'white',
    padding: 10,
    width: "150.5px",
    justifyContent: 'center'
  };
  var navlinkStyle = {
    textDecoration: "none"
  };

  return (
      <React.Fragment >
        <AppBar  style={headerStyle} position="sticky">
          {/* Editledim 22.02.2023 */}
          <Toolbar sx={{mx:0, px:"10px !important",}}> 
          <Container maxWidth="lg">   
            <Grid container  direction="row" justifyContent="space-between" alignItems="flex-end" sx={{mt:"30px !important",}}>
              <Grid item  sx={{mb: "10px !important"}}>
              {isDownMD ? ( 
                  
                <Button sx={{p:"0 !important", }}>
                  <NavLink to="/" style={navlinkStyle}>
                    <img src={Mscrm_logo} style= {{width: 100,paddingRight: 0,}} alt="" />
                  </NavLink>
                </Button>
                
              ):(
                <Button sx={{p:"0 !important", }}>
                  <NavLink to="/" style={navlinkStyle}>
                    <img src={Mscrm_logo} style={logoStyle} alt="" />
                  </NavLink>
                </Button>
              )}
              </Grid>
              {/* <Grid item > */}

                {/* <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={{ xl: 1, md: 1 }}> */}
                <Grid item >
                  <Grid container justifyContent="flex-end" alignItems="center" sx={{pb:"0px !important"}}>
                    <Grid item sx={{paddingRight: 1, paddingLeft: 10}}>
                      <MenuDrawers/>
                    </Grid>
                    <Grid item>
                        <IconButton style={buttonStyle} href="https://instagram.com/musicarium.live?igshid=YmMyMTA2M2Y" target="_blank">                        
                          <InstagramIcon style={iconStyle}/>
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton style={buttonStyle} href="https://www.facebook.com/musicariumentertainment" target="_blank">   
                          <FacebookIcon style={iconStyle}/>
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <IconButton style={buttonStyle} href="https://www.linkedin.com/company/musicariumentertainment/" target="_blank">   
                          <LinkedInIcon style={iconStyle}/>
                        </IconButton>
                      </Grid>
                  </Grid>
                </Grid>
            </Grid>
          </Container>
          </Toolbar>
        </AppBar>
      </React.Fragment>
  );
}

export default HeaderSmall;