import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// import concert7 from './images/concert-7.jpg'

const containerStyle = {
    height: 232,
    marginLeft: 0,
    marginRight: 0
}
const titleStyle = {
    fontFamily:'Poppins',
    fontSize: 35,
    fontWeight: 700, 
    color: '#231F1F',
    marginBottom: 10
}
const subTitleStyle = {
    fontFamily:'Poppins',
    fontSize: 30,
    fontWeight: 700, 
    color: '#231F1F',
}
const spotifyStyle = {
    backgroundColor: '#282828',
    height: '100%',
    width: '30%',
    maxWidth: 250,
}
const youtubeStyle = {
    backgroundColor: '#414141',
    height: '100%',
    width: '40%',
    maxWidth: 500,
}
const middleGridStyle = {
    backgroundColor: 'lightgrey',
    height: '100%',
    width: '50%',    
}
// const imageBackGround = {
//     backgroundImage: "url("+ concert7 +")",
//     height: '100%',
//     width: 'auto'
// }
//Card'a dönüştür

export default function ArtistAlbumCard({sanatciAdi, albumAdi, spofityLinki, youtubeLinki}) {
  return (
    <>
        <Card style={containerStyle} sx={{mx:5,mt:2}}>
            <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', width:'100%' }}>
                <Box style={spotifyStyle}>
                    {/* ESKİ YÖNTEM - KUTU VE ICON 
                    <CardMedia>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={SpotifyIcon} alt='Spotify' style={{width:60}}/>
                        </Box>    
                    </CardMedia>  */}
                    <iframe title="spotify" src={spofityLinki} width="100%" height="100%" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" borderRadius="0px"></iframe>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}  style={middleGridStyle}>
                    <Typography style={titleStyle}>{sanatciAdi}</Typography>
                    <Typography style={subTitleStyle}>{albumAdi}</Typography>
                </Box>
                <Box style={youtubeStyle}>
                    {/* ESKİ YÖNTEM - KUTU VE ICON  
                    <Paper style={imageBackGround}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <YouTubeIcon sx={{color: 'white', fontSize: 70}} />
                        </Box>
                    </Paper> */}
                    <iframe width="100%" height="100%" src={youtubeLinki} title="YouTube video player"></iframe>
                </Box>
            </Box>
        </Card>
    </>      
  )
}
