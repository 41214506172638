import React from 'react'
import ArtistSlider from "./ArtistSlider";
import gallery_5 from './images/GallerySlider/Buika_Ankara_CSO.jpg';
import gallery_7 from './images/GallerySlider/Buika_Uniq_İstanbul.jpg';
import gallery_19 from './images/GallerySlider/MFÖ_Harbiye.jpg';
import gallery_35 from './images/GallerySlider/Karsu_Maximiles_Black_Turgutreis_Arena.jpg'; 

import { useMediaQuery, useTheme, } from "@mui/material";

const slides = [
    { url: gallery_7, title: "Buika", text: "Uniq İstanbul"},
    { url: gallery_19, title: "MFÖ", text: "Harbiye"},
    { url: gallery_5, title: "Buika", text: "Ankara CSO" },
    { url: gallery_35, title: "Karsu", text: "Maximiles Black Turgutreis Arena"}

];
const containerLargeStyles = {
    width: "100%",
    height: "400px",
    margin: "0 auto",
  };
const containerSmallStyles = {
    width: "100%",
    height: "250px",
    margin: "0 auto",
  };


function ArtistSliderComp() {

    const theme = useTheme();
    const isDownMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
    {isDownMD ? ( 
        <div style={containerSmallStyles}>
            <ArtistSlider slides={slides}/>
        </div>
    ):(
        <div style={containerLargeStyles}>
            <ArtistSlider slides={slides}/>
        </div>
    )}
    </>
    
  )
}

export default ArtistSliderComp