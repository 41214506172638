import React from 'react'
import { Card,CardActionArea,CardMedia,Typography,CardContent,Grid } from "@mui/material";
import cardImage from './images/concert-1.jpg';
import ticketIcon from './images/ticket.svg'
import {useTranslation} from "react-i18next";
import IconButton from '@mui/material/IconButton';

const titleStyle={
  fontFamily:'Poppins',
  fontWeight: 600,
  marginBottom:0
}
const dateStyle={
  fontFamily:'Poppins',
  fontWeight: 400,
}


function EventCard({image,title,date,link}) {

  const {t, i18n}= useTranslation();


  return (
    <React.Fragment style={{}}>      
      <Card sx={{ maxWidth: 300, background:'#46328F', color:'white', borderRadius:0,margin: 1}} >
          <CardMedia component="img" height="300" image={image} alt="green iguana"/>
          <CardContent >
            <Grid container  direction="row" justifyContent="space-between" alignItems="center">
              <Grid item style={{width: '70%'}} >
                <Typography gutterBottom variant="h6" component="div" style={titleStyle}>
                {title}
                </Typography>
                <Typography variant="h6" style={dateStyle}>
                {date}
                </Typography>
              </Grid>
               <Grid item>
                <IconButton href={link}>
                  <img src={ticketIcon} alt='ticket' style={{width:40}}/>
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>
      </Card>
    </React.Fragment>

    )
}

export default EventCard