import React from 'react'
import ConcertCardLeft from './ConcertCardLeft'
import ConcertCardRight from './ConcertCardRight'
import ConcertCardSmall from './ConcertCardSmall' 
import Container from '@mui/material/Container' 
import { useMediaQuery, useTheme, } from "@mui/material";
import {useTranslation} from "react-i18next";

import gallery_1 from './images/Concerts/28 eylul 2022 safina.png';
import gallery_2 from './images/Concerts/23 ekim 2022 ayhan s.png';
import gallery_3 from './images/Concerts/Ayşegül Aldinç Büyük Kulüp.png';
import gallery_4 from './images/Concerts/2 haziran 2022 bgko feat suzan.png';
import gallery_5 from './images/Concerts/30 mayıs 2022 buika.png';
import gallery_6 from './images/Concerts/Buika Antalya, İstanbul Güncel.JPG';
import gallery_7 from './images/Concerts/Buika Antalya, İstanbul Güncel.JPG';
import gallery_8 from './images/Concerts/22 haziran 2022 fg feat burak kut.png';
import gallery_9 from './images/Concerts/8 haziran 2022 fg feat burcu gunes.png';
import gallery_10 from './images/Concerts/4 agustos 2022 ramos-antoine.png';
import gallery_11 from './images/Concerts/10 haziran 2022 dhafer youssef.png';
import gallery_12 from './images/Concerts/10 agustos 2022 fg feat fatma turgut.png';
import gallery_13 from './images/Concerts/22 agustos 2022 fg feat ferman akgul.png';
import gallery_14 from './images/Concerts/14 agustos 2022 gipsy kings.png';
import gallery_15 from './images/Concerts/6 temmuz 2022 fg feat ısın karaca.png';
import gallery_16 from './images/Concerts/Kovacs Antalya,Ankara, Ist Güncel.JPG';
import gallery_17 from './images/Concerts/Kovacs Antalya,Ankara, Ist Güncel.JPG';
import gallery_18 from './images/Concerts/Kovacs Antalya,Ankara, Ist Güncel.JPG';
import gallery_19 from './images/Concerts/27 ekim 2021 mfö.png';
import gallery_20 from './images/Concerts/Monica Molina Pera Palas.png';
import gallery_21 from './images/Concerts/26 ekim 2021 nil karaibrahimgil.png';
import gallery_22 from './images/Concerts/29 eylul 2022 domingo.png';
import gallery_23 from './images/Concerts/22 ekim 2021 serdar ortac.png';
import gallery_24 from './images/Concerts/20 temmuz 2022 fg feat yuksek sadakat.png';
import gallery_25 from './images/Concerts/Yüksek Sadakat Cermodern Güncel.JPG';
import gallery_26 from './images/Concerts/23 ekim 2021 yüksek üzere.png';
import gallery_27 from './images/Concerts/12 kasım 2021 mfö.png';
import gallery_28 from './images/Concerts/Cimri.jpg';
import gallery_29 from './images/Concerts/Mor ve Ötesi.jpg';
import gallery_30 from './images/Concerts/Yasmin Levy.jpg';
import gallery_31 from './images/Concerts/Karsu.jpg';
import gallery_32 from './images/Concerts/Goran Bregoviç.jpg';
import gallery_33 from './images/Concerts/Büyük Ev Ablukada.jpg';
import gallery_34 from './images/Concerts/Sevgili Arsız Ölüm - Dirmit.jpg';
import gallery_35 from './images/Concerts/Buika.jpg';
import gallery_36 from './images/Concerts/Deli Bayramı.jpg';
import gallery_37 from './images/Concerts/Evgeny Grinko.jpg';
import gallery_38 from './images/Concerts/Zengin Mutfağı.jpg';
import gallery_39 from './images/Concerts/Fuat Güner İBB Başakşehir K.M.JPG';
import gallery_40 from './images/Concerts/Purple Hand Band.jpg';
import gallery_41 from './images/Concerts/Buika Şubat 2024.jpg';
import gallery_42 from './images/Concerts/Simge Şubat 2024.jpg';
import gallery_43 from './images/Concerts/Monica Molina Eylül 2024.jpg';
import gallery_44 from './images/Concerts/27 Temmuz 2024 Gipsy Kings feat Tonino Baliardo.jpg';
import gallery_45 from './images/Concerts/26 Temmuz 2024 Gipsy Kings feat Tonino Baliardo.jpg';
import gallery_46 from './images/Concerts/25 Temmuz 2024 Aziza Mustafa Zadeh.jpg';
import gallery_47 from './images/Concerts/24 Temmuz 2024 Karsu.jpg';
import gallery_48 from './images/Concerts/5 Haziran 2024 Fuat Güner.jpg';
import gallery_49 from './images/Concerts/21 Mayıs 2024 Monica Molina.jpg';
import gallery_50 from './images/Concerts/7-9-10-11 Mayıs 2024 Haïdouti Orchestar.jpg';
import gallery_51 from './images/Concerts/5 Mayıs 2024 Lula Lounge Toronto.jpg';
import gallery_52 from './images/Concerts/1-3 Mayıs 2024 Fuat Güner.JPG';
import gallery_53 from './images/Concerts/MertDemir-6dekabr2.png';




export default function Concerts() {
  const theme = useTheme();
  const isDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const {t, i18n}= useTranslation();

  return (
    <>

      {isDownMD ? ( 
        <Container>
          <ConcertCardSmall eventName={t("concert.53.eventType")} date={t("concert.53.date")} artistName={t("concert.53.title")} location={t("concert.53.location")} text={t("concert.53.text")} coverImage={gallery_53} buyLink="https://iticket.az/en/events/concerts/mert-demir-bsp/118657"/>
          <ConcertCardSmall eventName={t("concert.43.eventType")} date={t("concert.43.date")} artistName={t("concert.43.title")} location={t("concert.43.location")} text={t("concert.43.text")} coverImage={gallery_43} buyLink="https://www.passo.com.tr/en/event/monica-molina-zorlupsm-turkcell-sahnesi-biletleri/5239323"/>
          <ConcertCardSmall eventName={t("concert.44.eventType")} date={t("concert.44.date")} artistName={t("concert.44.title")} location={t("concert.44.location")} text={t("concert.44.text")} coverImage={gallery_44} buyLink="https://biletinial.com/tr-tr/muzik/gipsy-kings-feat-tonino-baliardo"/>
          <ConcertCardSmall eventName={t("concert.45.eventType")} date={t("concert.45.date")} artistName={t("concert.45.title")} location={t("concert.45.location")} text={t("concert.45.text")} coverImage={gallery_45} buyLink="https://biletinial.com/tr-tr/muzik/gipsy-kings-feat-tonino-baliardo"/>          
          <ConcertCardSmall eventName={t("concert.46.eventType")} date={t("concert.46.date")} artistName={t("concert.46.title")} location={t("concert.46.location")} text={t("concert.46.text")} coverImage={gallery_46} buyLink="https://biletinial.com/tr-tr/muzik/aziza-mustafa-zadeh-konseri"/>
          <ConcertCardSmall eventName={t("concert.47.eventType")} date={t("concert.47.date")} artistName={t("concert.47.title")} location={t("concert.47.location")} text={t("concert.47.text")} coverImage={gallery_47} buyLink="https://biletinial.com/tr-tr/muzik/karsu-konseri"/>
          <ConcertCardSmall eventName={t("concert.48.eventType")} date={t("concert.48.date")} artistName={t("concert.48.title")} location={t("concert.48.location")} text={t("concert.48.text")} coverImage={gallery_48} buyLink="https://biletinial.com/tr-tr/muzik/fuat-guner-mfo-sarkilari-csoada"/>
          <ConcertCardSmall eventName={t("concert.49.eventType")} date={t("concert.49.date")} artistName={t("concert.49.title")} location={t("concert.49.location")} text={t("concert.49.text")} coverImage={gallery_49}/>
          <ConcertCardSmall eventName={t("concert.50.eventType")} date={t("concert.50.date")} artistName={t("concert.50.title")} location={t("concert.50.location")} text={t("concert.50.text")} coverImage={gallery_50} />
          <ConcertCardSmall eventName={t("concert.51.eventType")} date={t("concert.51.date")} artistName={t("concert.51.title")} location={t("concert.51.location")} text={t("concert.51.text")} coverImage={gallery_51}/>
          <ConcertCardSmall eventName={t("concert.52.eventType")} date={t("concert.52.date")} artistName={t("concert.52.title")} location={t("concert.52.location")} text={t("concert.52.text")} coverImage={gallery_52}/>
          <ConcertCardSmall eventName={t("concert.42.eventType")} date={t("concert.42.date")} artistName={t("concert.42.title")} location={t("concert.42.location")} text={t("concert.42.text")} coverImage={gallery_42} buyLink="https://iticket.az/en/events/concerts/simge-saghin/107028"/>
          <ConcertCardSmall eventName={t("concert.41.eventType")} date={t("concert.41.date")} artistName={t("concert.41.title")} location={t("concert.41.location")} text={t("concert.41.text")} coverImage={gallery_41} buyLink="https://www.eventim.de/en/event/buika-world-tour-2024-admiralspalast-17932368/?affiliate=TUG"/>
          <ConcertCardSmall eventName={t("concert.40.eventType")} date={t("concert.40.date")} artistName={t("concert.40.title")} location={t("concert.40.location")} text={t("concert.40.text")} coverImage={gallery_40} />
          <ConcertCardSmall eventName={t("concert.39.eventType")} date={t("concert.39.date")} artistName={t("concert.39.title")} location={t("concert.39.location")} text={t("concert.39.text")} coverImage={gallery_39} />
          <ConcertCardSmall eventName={t("concert.38.eventType")} date={t("concert.38.date")} artistName={t("concert.38.title")} location={t("concert.38.location")} text={t("concert.38.text")} coverImage={gallery_38} />
          <ConcertCardSmall eventName={t("concert.37.eventType")} date={t("concert.37.date")} artistName={t("concert.37.title")} location={t("concert.37.location")} text={t("concert.37.text")} coverImage={gallery_37} />
          <ConcertCardSmall eventName={t("concert.36.eventType")} date={t("concert.36.date")} artistName={t("concert.36.title")} location={t("concert.36.location")} text={t("concert.36.text")} coverImage={gallery_36} />
          <ConcertCardSmall eventName={t("concert.35.eventType")} date={t("concert.35.date")} artistName={t("concert.35.title")} location={t("concert.35.location")} text={t("concert.35.text")} coverImage={gallery_35} />
          <ConcertCardSmall eventName={t("concert.34.eventType")} date={t("concert.34.date")} artistName={t("concert.34.title")} location={t("concert.34.location")} text={t("concert.34.text")} coverImage={gallery_34} />
          <ConcertCardSmall eventName={t("concert.33.eventType")} date={t("concert.33.date")} artistName={t("concert.33.title")} location={t("concert.33.location")} text={t("concert.33.text")} coverImage={gallery_33} />
          <ConcertCardSmall eventName={t("concert.32.eventType")} date={t("concert.32.date")} artistName={t("concert.32.title")} location={t("concert.32.location")} text={t("concert.32.text")} coverImage={gallery_32} />
          <ConcertCardSmall eventName={t("concert.31.eventType")} date={t("concert.31.date")} artistName={t("concert.31.title")} location={t("concert.31.location")} text={t("concert.31.text")} coverImage={gallery_31} />
          <ConcertCardSmall eventName={t("concert.30.eventType")} date={t("concert.30.date")} artistName={t("concert.30.title")} location={t("concert.30.location")} text={t("concert.30.text")} coverImage={gallery_30} />
          <ConcertCardSmall eventName={t("concert.29.eventType")} date={t("concert.29.date")} artistName={t("concert.29.title")} location={t("concert.29.location")} text={t("concert.29.text")} coverImage={gallery_29} />
          <ConcertCardSmall eventName={t("concert.28.eventType")} date={t("concert.28.date")} artistName={t("concert.28.title")} location={t("concert.28.location")} text={t("concert.28.text")} coverImage={gallery_28} />
          <ConcertCardSmall eventName={t("concert.1.eventType")}date={t("concert.1.date")} artistName={t("concert.1.title")} location={t("concert.1.location")} text={t("concert.1.text")} coverImage={gallery_20}/>
          <ConcertCardSmall eventName={t("concert.2.eventType")}date={t("concert.2.date")} artistName={t("concert.2.title")} location={t("concert.2.location")} text={t("concert.2.text")} coverImage={gallery_2}/>
          <ConcertCardSmall eventName={t("concert.3.eventType")}date={t("concert.3.date")} artistName={t("concert.3.title")} location={t("concert.3.location")} text={t("concert.3.text")} coverImage={gallery_22}/>
          <ConcertCardSmall eventName={t("concert.4.eventType")}date={t("concert.4.date")} artistName={t("concert.4.title")} location={t("concert.4.location")} text={t("concert.4.text")} coverImage={gallery_1}/>
          <ConcertCardSmall eventName={t("concert.5.eventType")}date={t("concert.5.date")} artistName={t("concert.5.title")} location={t("concert.5.location")} text={t("concert.5.text")} coverImage={gallery_3}/>
          <ConcertCardSmall eventName={t("concert.6.eventType")}date={t("concert.6.date")} artistName={t("concert.6.title")} location={t("concert.6.location")} text={t("concert.6.text")} coverImage={gallery_25}/>
          <ConcertCardSmall eventName={t("concert.7.eventType")}date={t("concert.7.date")} artistName={t("concert.7.title")} location={t("concert.7.location")} text={t("concert.7.text")} coverImage={gallery_18}/>
          <ConcertCardSmall eventName={t("concert.8.eventType")}date={t("concert.8.date")} artistName={t("concert.8.title")} location={t("concert.8.location")} text={t("concert.8.text")} coverImage={gallery_16}/>
          <ConcertCardSmall eventName={t("concert.9.eventType")}date={t("concert.9.date")} artistName={t("concert.9.title")} location={t("concert.9.location")} text={t("concert.9.text")} coverImage={gallery_17}/>
          <ConcertCardSmall eventName={t("concert.10.eventType")}date={t("concert.10.date")} artistName={t("concert.10.title")} location={t("concert.10.location")} text={t("concert.10.text")} coverImage={gallery_7}/>
          <ConcertCardSmall eventName={t("concert.11.eventType")}date={t("concert.11.date")} artistName={t("concert.11.title")} location={t("concert.11.location")} text={t("concert.11.text")} coverImage={gallery_6}/>
          <ConcertCardSmall eventName={t("concert.12.eventType")}date={t("concert.12.date")} artistName={t("concert.12.title")} location={t("concert.12.location")} text={t("concert.12.text")} coverImage={gallery_13}/>
          <ConcertCardSmall eventName={t("concert.13.eventType")}date={t("concert.13.date")} artistName={t("concert.13.title")} location={t("concert.13.location")} text={t("concert.13.text")} coverImage={gallery_14}/>
          <ConcertCardSmall eventName={t("concert.14.eventType")}date={t("concert.14.date")} artistName={t("concert.14.title")} location={t("concert.14.location")} text={t("concert.14.text")} coverImage={gallery_12}/>
          <ConcertCardSmall eventName={t("concert.15.eventType")}date={t("concert.15.date")} artistName={t("concert.15.title")} location={t("concert.15.location")} text={t("concert.15.text")} coverImage={gallery_10}/>
          <ConcertCardSmall eventName={t("concert.16.eventType")}date={t("concert.16.date")} artistName={t("concert.16.title")} location={t("concert.16.location")} text={t("concert.16.text")} coverImage={gallery_24}/>
          <ConcertCardSmall eventName={t("concert.17.eventType")}date={t("concert.17.date")} artistName={t("concert.17.title")} location={t("concert.17.location")} text={t("concert.17.text")} coverImage={gallery_15}/>
          <ConcertCardSmall eventName={t("concert.18.eventType")}date={t("concert.18.date")} artistName={t("concert.18.title")} location={t("concert.18.location")} text={t("concert.18.text")} coverImage={gallery_8}/>
          <ConcertCardSmall eventName={t("concert.20.eventType")}date={t("concert.20.date")} artistName={t("concert.20.title")} location={t("concert.20.location")} text={t("concert.20.text")} coverImage={gallery_11}/>
          <ConcertCardSmall eventName={t("concert.19.eventType")}date={t("concert.19.date")} artistName={t("concert.19.title")} location={t("concert.19.location")} text={t("concert.19.text")} coverImage={gallery_9}/>
          <ConcertCardSmall eventName={t("concert.21.eventType")}date={t("concert.21.date")} artistName={t("concert.21.title")} location={t("concert.21.location")} text={t("concert.21.text")} coverImage={gallery_4}/>
          <ConcertCardSmall eventName={t("concert.22.eventType")}date={t("concert.22.date")} artistName={t("concert.22.title")} location={t("concert.22.location")} text={t("concert.22.text")} coverImage={gallery_5}/>
          <ConcertCardSmall eventName={t("concert.23.eventType")}date={t("concert.23.date")} artistName={t("concert.23.title")} location={t("concert.23.location")} text={t("concert.23.text")} coverImage={gallery_27} />
          <ConcertCardSmall eventName={t("concert.24.eventType")}date={t("concert.24.date")} artistName={t("concert.24.title")} location={t("concert.24.location")} text={t("concert.24.text")} coverImage={gallery_19}/>
          <ConcertCardSmall eventName={t("concert.25.eventType")}date={t("concert.25.date")} artistName={t("concert.25.title")} location={t("concert.25.location")} text={t("concert.25.text")} coverImage={gallery_21}/>
          <ConcertCardSmall eventName={t("concert.26.eventType")}date={t("concert.26.date")} artistName={t("concert.26.title")} location={t("concert.26.location")} text={t("concert.26.text")} coverImage={gallery_26}/>
          <ConcertCardSmall eventName={t("concert.27.eventType")}date={t("concert.27.date")} artistName={t("concert.27.title")} location={t("concert.27.location")} text={t("concert.27.text")} coverImage={gallery_23} />
          
        </Container>

      ):(
      <Container maxWidth="lg">

          <ConcertCardRight eventName={t("concert.53.eventType")} date={t("concert.53.date")} artistName={t("concert.53.title")} location={t("concert.53.location")} text={t("concert.53.text")} coverImage={gallery_53} buyLink="https://iticket.az/en/events/concerts/mert-demir-bsp/118657"/>
          <ConcertCardLeft eventName={t("concert.43.eventType")} date={t("concert.43.date")} artistName={t("concert.43.title")} location={t("concert.43.location")} text={t("concert.43.text")} coverImage={gallery_43} buyLink="https://www.passo.com.tr/en/event/monica-molina-zorlupsm-turkcell-sahnesi-biletleri/5239323" />
          <ConcertCardRight eventName={t("concert.44.eventType")} date={t("concert.44.date")} artistName={t("concert.44.title")} location={t("concert.44.location")} text={t("concert.44.text")} coverImage={gallery_44} buyLink="https://biletinial.com/tr-tr/muzik/gipsy-kings-feat-tonino-baliardo"/>
          <ConcertCardLeft eventName={t("concert.45.eventType")} date={t("concert.45.date")} artistName={t("concert.45.title")} location={t("concert.45.location")} text={t("concert.45.text")} coverImage={gallery_45} buyLink="https://biletinial.com/tr-tr/muzik/gipsy-kings-feat-tonino-baliardo"/>          
          <ConcertCardRight eventName={t("concert.46.eventType")} date={t("concert.46.date")} artistName={t("concert.46.title")} location={t("concert.46.location")} text={t("concert.46.text")} coverImage={gallery_46} buyLink="https://biletinial.com/tr-tr/muzik/aziza-mustafa-zadeh-konseri"/>
          <ConcertCardLeft eventName={t("concert.47.eventType")} date={t("concert.47.date")} artistName={t("concert.47.title")} location={t("concert.47.location")} text={t("concert.47.text")} coverImage={gallery_47} buyLink="https://biletinial.com/tr-tr/muzik/karsu-konseri"/>
          <ConcertCardRight eventName={t("concert.48.eventType")} date={t("concert.48.date")} artistName={t("concert.48.title")} location={t("concert.48.location")} text={t("concert.48.text")} coverImage={gallery_48} buyLink="https://biletinial.com/tr-tr/muzik/fuat-guner-mfo-sarkilari-csoada"/>
          <ConcertCardLeft eventName={t("concert.49.eventType")} date={t("concert.49.date")} artistName={t("concert.49.title")} location={t("concert.49.location")} text={t("concert.49.text")} coverImage={gallery_49}/>
          <ConcertCardRight eventName={t("concert.50.eventType")} date={t("concert.50.date")} artistName={t("concert.50.title")} location={t("concert.50.location")} text={t("concert.50.text")} coverImage={gallery_50} />
          <ConcertCardLeft eventName={t("concert.51.eventType")} date={t("concert.51.date")} artistName={t("concert.51.title")} location={t("concert.51.location")} text={t("concert.51.text")} coverImage={gallery_51}/>
          <ConcertCardRight eventName={t("concert.52.eventType")} date={t("concert.52.date")} artistName={t("concert.52.title")} location={t("concert.52.location")} text={t("concert.52.text")} coverImage={gallery_52}/>
          <ConcertCardLeft eventName={t("concert.42.eventType")} date={t("concert.42.date")} artistName={t("concert.42.title")} location={t("concert.42.location")} text={t("concert.42.text")} coverImage={gallery_42} buyLink="https://iticket.az/en/events/concerts/simge-saghin/107028"/>
          <ConcertCardRight eventName={t("concert.41.eventType")} date={t("concert.41.date")} artistName={t("concert.41.title")} location={t("concert.41.location")} text={t("concert.41.text")} coverImage={gallery_41} buyLink="https://www.eventim.de/en/event/buika-world-tour-2024-admiralspalast-17932368/?affiliate=TUG"/>
          <ConcertCardLeft eventName={t("concert.40.eventType")} date={t("concert.40.date")} artistName={t("concert.40.title")} location={t("concert.40.location")} text={t("concert.40.text")} coverImage={gallery_40} />
          <ConcertCardRight eventName={t("concert.39.eventType")} date={t("concert.39.date")} artistName={t("concert.39.title")} location={t("concert.39.location")} text={t("concert.39.text")} coverImage={gallery_39} />
          <ConcertCardLeft eventName={t("concert.38.eventType")} date={t("concert.38.date")} artistName={t("concert.38.title")} location={t("concert.38.location")} text={t("concert.38.text")} coverImage={gallery_38} />
          <ConcertCardRight eventName={t("concert.37.eventType")} date={t("concert.37.date")} artistName={t("concert.37.title")} location={t("concert.37.location")} text={t("concert.37.text")} coverImage={gallery_37} />
          <ConcertCardLeft eventName={t("concert.36.eventType")} date={t("concert.36.date")} artistName={t("concert.36.title")} location={t("concert.36.location")} text={t("concert.36.text")} coverImage={gallery_36} />
          <ConcertCardRight eventName={t("concert.35.eventType")} date={t("concert.35.date")} artistName={t("concert.35.title")} location={t("concert.35.location")} text={t("concert.35.text")} coverImage={gallery_35} />
          <ConcertCardLeft eventName={t("concert.34.eventType")} date={t("concert.34.date")} artistName={t("concert.34.title")} location={t("concert.34.location")} text={t("concert.34.text")} coverImage={gallery_34} />
          <ConcertCardRight eventName={t("concert.33.eventType")} date={t("concert.33.date")} artistName={t("concert.33.title")} location={t("concert.33.location")} text={t("concert.33.text")} coverImage={gallery_33} />
          <ConcertCardLeft eventName={t("concert.32.eventType")} date={t("concert.32.date")} artistName={t("concert.32.title")} location={t("concert.32.location")} text={t("concert.32.text")} coverImage={gallery_32} />
          <ConcertCardRight eventName={t("concert.31.eventType")} date={t("concert.31.date")} artistName={t("concert.31.title")} location={t("concert.31.location")} text={t("concert.31.text")} coverImage={gallery_31} />
          <ConcertCardLeft eventName={t("concert.30.eventType")} date={t("concert.30.date")} artistName={t("concert.30.title")} location={t("concert.30.location")} text={t("concert.30.text")} coverImage={gallery_30} />
          <ConcertCardRight eventName={t("concert.29.eventType")} date={t("concert.29.date")} artistName={t("concert.29.title")} location={t("concert.29.location")} text={t("concert.29.text")} coverImage={gallery_29} />
          <ConcertCardLeft eventName={t("concert.28.eventType")} date={t("concert.28.date")} artistName={t("concert.28.title")} location={t("concert.28.location")} text={t("concert.28.text")} coverImage={gallery_28} />
          <ConcertCardRight eventName={t("concert.1.eventType")}date={t("concert.1.date")} artistName={t("concert.1.title")} location={t("concert.1.location")} text={t("concert.1.text")} coverImage={gallery_20}/>
          <ConcertCardLeft eventName={t("concert.2.eventType")}date={t("concert.2.date")} artistName={t("concert.2.title")} location={t("concert.2.location")} text={t("concert.2.text")} coverImage={gallery_2}/>
          <ConcertCardRight eventName={t("concert.3.eventType")}date={t("concert.3.date")} artistName={t("concert.3.title")} location={t("concert.3.location")} text={t("concert.3.text")} coverImage={gallery_22}/>
          <ConcertCardLeft eventName={t("concert.4.eventType")}date={t("concert.4.date")} artistName={t("concert.4.title")} location={t("concert.4.location")} text={t("concert.4.text")} coverImage={gallery_1}/>
          <ConcertCardRight eventName={t("concert.5.eventType")}date={t("concert.5.date")} artistName={t("concert.5.title")} location={t("concert.5.location")} text={t("concert.5.text")} coverImage={gallery_3}/>
          <ConcertCardLeft eventName={t("concert.6.eventType")}date={t("concert.6.date")} artistName={t("concert.6.title")} openingArtist={t("concert.6.openingArtist")} location={t("concert.6.location")} text={t("concert.6.text")} coverImage={gallery_25}/>
          <ConcertCardRight eventName={t("concert.7.eventType")}date={t("concert.7.date")} artistName={t("concert.7.title")} location={t("concert.7.location")} text={t("concert.7.text")} coverImage={gallery_18}/>
          <ConcertCardLeft eventName={t("concert.8.eventType")}date={t("concert.8.date")} artistName={t("concert.8.title")} location={t("concert.8.location")} text={t("concert.8.text")} coverImage={gallery_16}/>
          <ConcertCardRight eventName={t("concert.9.eventType")}date={t("concert.9.date")} artistName={t("concert.9.title")} location={t("concert.9.location")} text={t("concert.9.text")} coverImage={gallery_17}/>
          <ConcertCardLeft eventName={t("concert.10.eventType")}date={t("concert.10.date")} artistName={t("concert.10.title")} location={t("concert.10.location")} text={t("concert.10.text")} coverImage={gallery_7}/>
          <ConcertCardRight eventName={t("concert.11.eventType")}date={t("concert.11.date")} artistName={t("concert.11.title")} location={t("concert.11.location")} text={t("concert.11.text")} coverImage={gallery_6}/>
          <ConcertCardLeft eventName={t("concert.12.eventType")}date={t("concert.12.date")} artistName={t("concert.12.title")} location={t("concert.12.location")} text={t("concert.12.text")} coverImage={gallery_13}/>
          <ConcertCardRight eventName={t("concert.13.eventType")}date={t("concert.13.date")} artistName={t("concert.13.title")} location={t("concert.13.location")} text={t("concert.13.text")} coverImage={gallery_14}/>
          <ConcertCardLeft eventName={t("concert.14.eventType")}date={t("concert.14.date")} artistName={t("concert.14.title")} location={t("concert.14.location")} text={t("concert.14.text")} coverImage={gallery_12}/>
          <ConcertCardRight eventName={t("concert.15.eventType")}date={t("concert.15.date")} artistName={t("concert.15.title")} location={t("concert.15.location")} text={t("concert.15.text")} coverImage={gallery_10}/>
          <ConcertCardLeft eventName={t("concert.16.eventType")}date={t("concert.16.date")} artistName={t("concert.16.title")} location={t("concert.16.location")} text={t("concert.16.text")} coverImage={gallery_24}/>
          <ConcertCardRight eventName={t("concert.17.eventType")}date={t("concert.17.date")} artistName={t("concert.17.title")} location={t("concert.17.location")} text={t("concert.17.text")} coverImage={gallery_15}/>
          <ConcertCardLeft eventName={t("concert.18.eventType")}date={t("concert.18.date")} artistName={t("concert.18.title")} location={t("concert.18.location")} text={t("concert.18.text")} coverImage={gallery_8}/>
          <ConcertCardRight eventName={t("concert.20.eventType")}date={t("concert.20.date")} artistName={t("concert.20.title")} location={t("concert.20.location")} text={t("concert.20.text")} coverImage={gallery_11}/>
          <ConcertCardLeft eventName={t("concert.19.eventType")}date={t("concert.19.date")} artistName={t("concert.19.title")} location={t("concert.19.location")} text={t("concert.19.text")} coverImage={gallery_9}/>
          <ConcertCardRight eventName={t("concert.21.eventType")}date={t("concert.21.date")} artistName={t("concert.21.title")} location={t("concert.21.location")} text={t("concert.21.text")} coverImage={gallery_4}/>
          <ConcertCardLeft eventName={t("concert.22.eventType")}date={t("concert.22.date")} artistName={t("concert.22.title")} location={t("concert.22.location")} text={t("concert.22.text")} coverImage={gallery_5}/>
          <ConcertCardRight eventName={t("concert.23.eventType")}date={t("concert.23.date")} artistName={t("concert.23.title")} location={t("concert.23.location")} text={t("concert.23.text")} coverImage={gallery_27} />
          <ConcertCardLeft eventName={t("concert.24.eventType")}date={t("concert.24.date")} artistName={t("concert.24.title")} location={t("concert.24.location")} text={t("concert.24.text")} coverImage={gallery_19}/>
          <ConcertCardRight eventName={t("concert.25.eventType")}date={t("concert.25.date")} artistName={t("concert.25.title")} location={t("concert.25.location")} text={t("concert.25.text")} coverImage={gallery_21}/>
          <ConcertCardLeft eventName={t("concert.26.eventType")}date={t("concert.26.date")} artistName={t("concert.26.title")} location={t("concert.26.location")} text={t("concert.26.text")} coverImage={gallery_26}/>
          <ConcertCardRight eventName={t("concert.27.eventType")}date={t("concert.27.date")} artistName={t("concert.27.title")} location={t("concert.27.location")} text={t("concert.27.text")} coverImage={gallery_23} />

       
      </Container>

      )}
        
    </>
  )
}
